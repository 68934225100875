import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/auth.service';
@Component({
  selector: 'webapp-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  public token;
  public tncBaseUrl;
  @ViewChild('tncRef', { static: false }) tncRef: ElementRef;
  @ViewChild('binPdfRef', { static: false }) binPdfRef: ElementRef;

  constructor(private router: Router, private authService: AuthService) {}

  onBackButton() {
    this.router.navigate(['/app']);
  }
  ngAfterViewInit() {
    const godUserPermissions = {
      userId: this.authService.getTokenInfo().claims.accountUserId,
      accountId: this.authService.getTokenInfo().claims.accountUserId,
      isGodUser: true,
      isMainUser: true,
    };
    const godUserPermissionsPdf = {
      userId: undefined,
      accountId: undefined,
      isGodUser: true,
      isMainUser: true,
    };
    const _tc = this.tncRef.nativeElement;
    const _binRef = this.binPdfRef.nativeElement;
    _tc.baseUrl = environment.BASE_URL;
    _tc.authToken = this.authService.getTokenInfo().accessToken;
    _tc.userPermissions = this.getPermissions(godUserPermissions);

    _binRef.baseUrl = environment.BASE_URL;
    _binRef.authToken = this.authService.getTokenInfo().accessToken;
    _binRef.userPermissions = this.getPermissionsPdf(godUserPermissionsPdf);
  }
  ngOnInit() {
    console.log(this.authService.getTokenInfo());
  }
  getPermissions(permissions) {
    return {
      ...permissions,
      userId: localStorage.getItem('UserID'),
      accountId: this.authService.getTokenInfo().claims.accountUserId,
    };
  }
  getPermissionsPdf(permissions) {
    return Object.assign({}, permissions, {
      userId: localStorage.getItem('UserID'),
      accountId: localStorage.getItem('accountId'),
    });
  }
}
