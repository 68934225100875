import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, zip } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from '../../../core/auth.service';
import { LookupService, EquipmentLookup, ModeLookup } from '../lookup-service';

@Injectable({
  providedIn: 'root',
})
export class ListViewService {
  private base_url = environment.PRIME_URL;
  private load_url = `${this.base_url}/truckstopprime/v1`;
  private equipmentTypes: EquipmentLookup[];
  private modes: ModeLookup[];
  constructor(private http: HttpClient, private lookupService: LookupService) {}

  public GetLoads() {
    const dto = JSON.stringify({
      pagination: {
        pageNumber: 1,
        pageSize: 1000000,
      },
    });
    const req = this.http.post(this.load_url + '/load/search', dto, {
      headers: this.lookupService.headers(),
    });
    return forkJoin([
      this.lookupService.LookupEquipmentTypes(),
      this.lookupService.LookupMode(),
      req,
    ]).pipe(
      map((res) => {
        this.equipmentTypes = res[0] as EquipmentLookup[];
        this.modes = res[1] as ModeLookup[];
        const load = res[2] as any;
        return load.data.map((x) => this.MapData(x));
      })
    );
  }
  public MapData(data) {
    const loadContract = data.loadResponseV2Contract;
    const equipmentAttributes = loadContract.equipmentAttributes;
    const equipment = this.equipmentTypes.find(
      (x) => x.id === equipmentAttributes.equipmentTypeId
    );
    const mode = this.modes.find(
      (x) => x.modeId === equipmentAttributes.transportationModeId
    );
    const date = new Date(data.load.pickupDate);
    const dateFormat = new Intl.DateTimeFormat('en-US', {
      month: 'numeric',
      day: 'numeric',
    }).format(date);

    const timeFormt = new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);

    const dto = {
      status: data.loadStatusName,
      customer: data.shipperName,
      origin: this.MapLocation(
        data.load.origin ? data.load.origin.location : {}
      ),
      destination: this.MapLocation(
        data.load.destination ? data.load.destination.location : {}
      ),
      pickupdate: `${dateFormat} ${timeFormt}`,
      stops: data.load.loadStopsCount,
      equipment: equipment ? equipment.code : '',
      // mode: mode ? mode.modeCode : '',
      load: loadContract.loadNumber,
      label: loadContract.loadLabel,
      rate: data.load.shipperRate,
      ratings: data.carrierRating ? data.carrierRating.rating : '',
    };
    return dto;
  }
  public MapLocation(location) {
    let address = '';
    // if (location.streetAddress1) {
    //   address += location.streetAddress1;
    // }
    // if (location.streetAddress2) {
    //   address += `, ${location.streetAddress2}`;
    // }
    if (location.city) {
      address += `${location.city}`;
    }
    if (location.state) {
      address += `, ${location.state}`;
    }
    // if (location.countryCode) {
    //   address += `, ${location.countryCode}`;
    // }
    return address;
  }
}
