<mat-toolbar class="header">
  <ts-icon
    name="arrow-left"
    class="mr-3 arrow-left"
    (click)="onBackButton()"
  ></ts-icon>
  <span>Customer Details</span>
</mat-toolbar>
<mat-tab-group class="details" [selectedIndex]="activeTab">
  <mat-tab label="BUSINESS">
    <div>
      <div class="header font-weight-bold pt-4 ml-4">Company Information:</div>
    </div>
    <div class="row ml-4 mt-4">
      <div class="col-2">
        <label class="hoverable" for="fileInput">
          <img
            [src]="url ? url : '../../../assets/icons/svg/ellipse82.png'"
            height="100"
            width="100"
            class="profile-upload"
          />
          <div class="hover-text">
            <ts-icon name="camera" class="camera-icon"></ts-icon>
          </div>

          <div class="background"></div>
        </label>
        <br />
        <input id="fileInput" type="file" (change)="onSelectFile($event)" />
      </div>
      <div class="col-4">
        <span>
          Company Name
        </span>
        <form [formGroup]="bussinessFormGroup">
          <p class="pt-2">
            <mat-form-field class="w-100">
              <mat-label> Company Name</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="companyName"
                required
              />
              <mat-error
                *ngIf="
                  bussinessFormGroup.controls.companyName.hasError('required')
                "
              >
                Company Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label> Doing Business As</mat-label>
              <input matInput placeholder="" formControlName="businessType" />
            </mat-form-field>
          </p>
          <p>
            <!-- <mat-form-field class="w-100">
              <mat-label> Industry</mat-label>
              <input matInput placeholder="" formControlName="industry" />
            </mat-form-field> -->
            <mat-form-field class="w-100">
              <mat-label> Industry</mat-label>
              <input
                matInput
                placeholder=""
                [matAutocomplete]="industryAuto"
                formControlName="industry"
                (keyup)="doIndustrySearch($event)"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete
                #industryAuto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let industry of filteredIndustry"
                  [value]="industry"
                >
                  <span>{{ industry.definition }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>

          <p>
            <label id="example-radio-group-label">Select Your Company ID</label>
          </p>
          <mat-radio-group
            formControlName="companyId"
            aria-label="Select Your Company ID"
          >
            <mat-radio-button value="11111111110">SSN</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="taxId">Federal TaxID#</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="33434343435">EIN</mat-radio-button>
          </mat-radio-group>
          <p>
            <mat-form-field class="w-100">
              <mat-label>Country of Incorporation</mat-label>
              <input
                type="text"
                matInput
                formControlName="countryOfIncorporation"
                [matAutocomplete]="countryAuto"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete #countryAuto="matAutocomplete">
                <mat-option *ngFor="let option of country" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label>State of Incorporation </mat-label>
              <input
                type="text"
                matInput
                formControlName="stateOfIncorporation"
                [matAutocomplete]="auto"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of states" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label> Website</mat-label>
              <input matInput placeholder="www" formControlName="website" />
            </mat-form-field>
          </p>
          <div>
            <button
              mat-button
              class="update-btn mt-3"
              [disabled]="!bussinessFormGroup.valid"
              (click)="updateShipper(bussinessFormGroup.value)"
            >
              UPDATE
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="ADDRESS">
    <div>
      <div class="header font-weight-bold pt-4 ml-4">Company Address</div>
    </div>
    <div>
      <form [formGroup]="addressFormGroup">
        <div class="container ml-5 mt-3">
          <span class="black-lable py-3">Main Address:</span>
          <div class="row">
            <div class="col-4">
              <mat-form-field class="w-100">
                <mat-label>ZIP Code</mat-label>
                <input matInput formControlName="mainZipCode" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Address Line-1</mat-label>
                <input matInput formControlName="mainAddress1" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>City</mat-label>
                <input matInput formControlName="mainCity" />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <mat-label>Country</mat-label>
                <input matInput formControlName="mainCountryCode" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Address Line-2</mat-label>
                <input matInput formControlName="mainAddress2" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>State</mat-label>
                <input matInput formControlName="mainStateCode" />
              </mat-form-field>
            </div>
          </div>
          <p class="black-lable py-3">Billing Address:</p>
          <mat-checkbox
            class="example-margin"
            #billing
            (click)="checkBillingAddress(!billing.checked)"
            >Billing address same as main address</mat-checkbox
          >
          <div class="row">
            <div class="col-4">
              <mat-form-field class="w-100">
                <mat-label>ZIP Code</mat-label>
                <input matInput formControlName="billingZipCode" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Address Line-1</mat-label>
                <input matInput formControlName="billingAddress1" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>City</mat-label>
                <input matInput formControlName="billingCity" />
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field class="w-100">
                <mat-label>Country</mat-label>
                <input matInput formControlName="billingCountryCode" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Address Line-2</mat-label>
                <input matInput formControlName="billingAddress2" />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>State</mat-label>
                <input matInput formControlName="billingStateCode" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="pl-5">
          <button
            mat-button
            class="update-btn mt-3"
            (click)="updateShipper(addressFormGroup.value)"
          >
            UPDATE
          </button>
        </div>
      </form>
    </div>
  </mat-tab>
  <mat-tab label="CONTACTS">
    <div></div>
    <div class="row py-3 mx-1">
      <div class="col-2 header font-weight-bold justify-content-start">
        Contact Information:
      </div>

      <div class="col-10 justify-content-end d-flex">
        <button mat-button class="update-btn" (click)="addContact()">
          <ts-icon name="plus" class="add-icon"></ts-icon> ADD CONTACT
        </button>
      </div>
    </div>

    <div class="row contact-bg-color mx-3">
      <div class="col">Contact Type</div>
      <div class="col">First Name</div>
      <div class="col">Last Name</div>
      <div class="col">Phone</div>
      <div class="col">Ext</div>
      <div class="col">Mobile(Optional)</div>
      <div class="col">Email</div>
      <div class="col">Action</div>
    </div>
    <div
      class="row contact-detail-color my-3 mx-3"
      *ngFor="let contact of shipperContacts"
    >
      <div class="col">{{ contact.contactTypeName }}</div>
      <div class="col">{{ contact.firstName }}</div>
      <div class="col">{{ contact.lastName }}</div>
      <div class="col">{{ contact.phone }}</div>
      <div class="col">{{ contact.ext }}</div>
      <div class="col">{{ contact.mobile }}</div>
      <div class="col">{{ contact.email }}</div>
      <div class="col d-flex">
        <ts-icon
          name="pencil"
          class="pencil ml-3 gray edit-action"
          (click)="editContact(contact)"
        ></ts-icon>
        <ts-icon
          name="close"
          class="close delete-action"
          (click)="confirmDialog(contact)"
        ></ts-icon>
      </div>
    </div>
    <div *ngIf="shipperContacts && !shipperContacts.length" class="text-center">
      <label for="No Shipper contact added" class="contact-label"
        >No Shipper Contact added</label
      >
    </div>
  </mat-tab>
  <mat-tab label="BILLING">
    <div>
      <div class="header font-weight-bold pt-4 ml-4">Billing Information:</div>
    </div>
    <div>
      <form [formGroup]="billingFormGroup">
        <div class="container pt-2 ml-4 mt-3 w-50">
          <p class="pt-2 lable-color">Invoicing:</p>
          <p class="pt-1">
            Provide the customer’s preferred method to receive a bill/invoice.
          </p>
          <label id="example-radio-group-label">Originals Required</label>
          <mat-radio-group
            class="example-radio-group pb-2"
            formControlName="isOriginalBillRequired"
          >
            <mat-radio-button value="true">YES</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="false" [checked]="true"
              >NO</mat-radio-button
            >
          </mat-radio-group>

          <mat-radio-group class="example-radio-group pb-2">
            <mat-radio-button
              value="email"
              [checked]="billingFormGroup.value.email === null ? false : true"
              >Email</mat-radio-button
            >
          </mat-radio-group>
          <mat-form-field class="w-100">
            <input matInput formControlName="email" />
          </mat-form-field>
          <mat-radio-button value="usmail" class="pt-3"
            >US Mail</mat-radio-button
          >&nbsp;&nbsp;&nbsp;
          <mat-form-field class="w-100">
            <input matInput formControlName="usMail" />
          </mat-form-field>
          <p class="lable-color">Payments:</p>
          <label class="example-radio-group"
            >Provide the customer’s preferred method for making a
            payment.</label
          >
          <mat-radio-group formControlName="billPaymentModeId">
            <mat-radio-button
              class="example-radio-button pt-1 pr-2"
              *ngFor="let mode of payments"
              [value]="mode.modeId"
            >
              {{ mode.name }}
            </mat-radio-button>
          </mat-radio-group>
          <div class="pt-3">
            <button
              mat-button
              class="update-btn mt-3"
              (click)="updateShipper(billingFormGroup.value)"
            >
              UPDATE
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-tab>
  <!-- <mat-tab label="SETTINGS"> Settings </mat-tab> -->
</mat-tab-group>
<div>
  <div class="row m-0 bg-color"></div>
</div>
