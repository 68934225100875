import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  BASE_URL: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  get(path: string, baseURL?: string, params?: any): Observable<any> {
    const url = `${baseURL || this.BASE_URL}${path}`;
    return this.http.get(url, params);
  }

  post(path: string, body: any, baseURL?: string): Observable<any> {
    const url = `${baseURL || this.BASE_URL}${path}`;
    return this.http.post(url, body);
  }

  put(path: string, body: any, baseURL?: string): Observable<any> {
    const url = `${baseURL || this.BASE_URL}${path}`;
    return this.http.put(url, body);
  }

  delete(path: string, baseURL?: string): Observable<any> {
    const url = `${baseURL || this.BASE_URL}${path}`;
    return this.http.delete(url);
  }

  patch(path: string, body: any, baseURL?: string): Observable<any> {
    const url = `${baseURL || this.BASE_URL}${path}`;
    return this.http.patch(url, body);
  }
}
