<mat-toolbar class="header">
  <ts-icon name="arrow-left" class="mr-3" (click)="onBackButton()"></ts-icon>
  <span>Add New Customer</span>
</mat-toolbar>

<div>
  <div class="row m-0 bg-color">
    <div class="col-6 header font-weight-bold pt-4">Customer Account Setup</div>
    <mat-vertical-stepper #stepper class="w-100">
      <mat-step [stepControl]="bussinessFormGroup">
        <form [formGroup]="bussinessFormGroup">
          <ng-template matStepLabel>Bussiness</ng-template>
          <div class="col-6 p-0 black-lable">Company Information</div>
          <p class="pt-2">
            <mat-form-field class="w-100">
              <mat-label> Company Name</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="companyName"
                required
              />
              <mat-error
                *ngIf="
                  bussinessFormGroup.controls.companyName.hasError('required')
                "
              >
                Company Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label> Doing Business As</mat-label>
              <input matInput placeholder="" formControlName="businessType" />
            </mat-form-field>
          </p>
          <p>
            <!-- <mat-form-field class="w-100">
              <mat-label> Industry</mat-label>
              <input matInput placeholder="" formControlName="industry" />
            </mat-form-field> -->
            <mat-form-field class="w-100">
              <mat-label> Industry</mat-label>
              <input
                matInput
                placeholder=""
                [matAutocomplete]="industryAuto"
                formControlName="industry"
                (keyup)="doIndustrySearch($event)"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete
                #industryAuto="matAutocomplete"
                [displayWith]="displayFn"
              >
                <mat-option
                  *ngFor="let industry of filteredIndustry"
                  [value]="industry"
                >
                  <span>{{ industry.definition }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>

          <p>
            <label id="example-radio-group-label">Select Your Company ID</label>
          </p>
          <mat-radio-group
            formControlName="companyId"
            aria-label="Select Your Company ID"
          >
            <mat-radio-button value="1234567899">SSN</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="taxId">Federal TaxID#</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="33434343434">EIN</mat-radio-button>
          </mat-radio-group>
          <p>
            <mat-form-field class="w-100">
              <mat-label>Country of Incorporation</mat-label>
              <input
                type="text"
                matInput
                formControlName="countryOfIncorporation"
                [matAutocomplete]="countryAuto"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete #countryAuto="matAutocomplete">
                <mat-option *ngFor="let option of country" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label>State of Incorporation </mat-label>
              <input
                type="text"
                matInput
                formControlName="stateOfIncorporation"
                [matAutocomplete]="auto"
              />
              <div
                class="mat-select-arrow-wrapper position-absolute auto-complete-arrow"
              >
                <div class="mat-select-arrow"></div>
              </div>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of states" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="w-100">
              <mat-label> Website</mat-label>
              <input matInput placeholder="www" formControlName="website" />
            </mat-form-field>
          </p>
          <div>
            <button
              mat-button
              matStepperNext
              class="nextbtn"
              [disabled]="!bussinessFormGroup.valid"
            >
              NEXT
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="addressFormGroup">
        <form [formGroup]="addressFormGroup">
          <ng-template matStepLabel>Address</ng-template>
          <div class="container">
            <span class="black-lable py-3">Main Address:</span>
            <div class="row">
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>ZIP Code</mat-label>
                  <input matInput formControlName="mainZipCode" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Address Line-1</mat-label>
                  <input matInput formControlName="mainAddress1" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="mainCity" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>Country</mat-label>
                  <input matInput formControlName="mainCountryCode" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Address Line-2</mat-label>
                  <input matInput formControlName="mainAddress2" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>State</mat-label>
                  <input matInput formControlName="mainStateCode" />
                </mat-form-field>
              </div>
            </div>
            <p class="black-lable py-3">Billing Address:</p>
            <mat-checkbox
              class="example-margin"
              #billing
              (click)="checkBillingAddress(!billing.checked)"
              >Billing address same as main address</mat-checkbox
            >
            <div class="row">
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>ZIP Code</mat-label>
                  <input matInput formControlName="billingZipCode" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Address Line-1</mat-label>
                  <input matInput formControlName="billingAddress1" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="billingCity" />
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="w-100">
                  <mat-label>Country</mat-label>
                  <input matInput formControlName="billingCountryCode" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Address Line-2</mat-label>
                  <input matInput formControlName="billingAddress2" />
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>State</mat-label>
                  <input matInput formControlName="billingStateCode" />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="pl-3 pt-3">
            <button mat-button matStepperPrevious class="backtbtn mr-3">
              BACK
            </button>
            <button mat-button matStepperNext class="nextbtn">NEXT</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="contactsFormGroup">
        <form [formGroup]="contactsFormGroup">
          <ng-template matStepLabel>Contacts</ng-template>
          <div formArrayName="contactsArray">
            <div
              class="container"
              *ngFor="
                let contacts of contactsFormGroup.get('contactsArray')[
                  'controls'
                ];
                let i = index
              "
              [formGroupName]="i"
            >
              <div
                class="add-contact pt-3 pb-3"
                style="float: right;"
                (click)="removeOrClearContact(i)"
              >
                <ts-icon name="minus-circle"></ts-icon>
              </div>
              <span class="black-lable py-3"
                >Contact Information: {{ i + 1 }}</span
              >
              <div class="row pt-2">
                <div class="col-6">
                  <mat-checkbox
                    #primary
                    (click)="toggle(!primary.checked)"
                    formControlName="isPrimary"
                    >Set As Primary Contact</mat-checkbox
                  >
                  <!-- <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Contact Type</mat-label>
                    <mat-select formControlName="contactType">
                      <mat-option
                        *ngFor="let item of contactType"
                        [value]="item"
                      >
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <mat-form-field class="w-100">
                    <mat-label>Contact Type</mat-label>
                    <input matInput formControlName="contactTypeName" />
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" />
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <mat-form-field class="w-100">
                    <mat-label>Phone</mat-label>
                    <input matInput formControlName="phone" />
                  </mat-form-field>
                </div>
                <div class="col-1">
                  <mat-form-field class="w-100">
                    <mat-label>Ext</mat-label>
                    <input matInput formControlName="ext" />
                  </mat-form-field>
                </div>
                <div class="col-3">
                  <mat-form-field class="w-100">
                    <mat-label>Mobile Phone (Optional)</mat-label>
                    <input matInput formControlName="mobile" />
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="w-100">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="email" />
                  </mat-form-field>
                </div>
              </div>
              <div class="add-contact pt-3 pb-3" (click)="addContactInput()">
                <ts-icon name="plus" class="add-icon"></ts-icon> Add Contact
              </div>
            </div>
          </div>
          <div class="pl-3 pt-3">
            <button mat-button matStepperPrevious class="backtbtn mr-3">
              BACK
            </button>
            <button mat-button matStepperNext class="nextbtn">NEXT</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="billingFormGroup">
        <form [formGroup]="billingFormGroup">
          <ng-template matStepLabel>Billing</ng-template>
          <div class="container pt-2">
            <span class="black-lable py-3">Billing Information:</span>
            <p class="pt-2 lable-color">Invoicing:</p>
            <p class="pt-1">
              Provide the customer’s preferred method to receive a bill/invoice.
            </p>
            <label id="example-radio-group-label">Originals Required</label>
            <mat-radio-group
              class="example-radio-group pb-2"
              formControlName="isOriginalBillRequired"
            >
              <mat-radio-button value="true">YES</mat-radio-button
              >&nbsp;&nbsp;&nbsp;
              <mat-radio-button value="false" [checked]="true"
                >NO</mat-radio-button
              >
            </mat-radio-group>

            <mat-radio-button value="email" [checked]="true"
              >Email</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-form-field class="w-100">
              <input matInput formControlName="email" />
            </mat-form-field>
            <mat-radio-button value="usmail" class="pt-3"
              >US Mail</mat-radio-button
            >&nbsp;&nbsp;&nbsp;
            <mat-form-field class="w-100">
              <input matInput formControlName="usMail" />
            </mat-form-field>
            <p class="lable-color">Payments:</p>
            <label class="example-radio-group"
              >Provide the customer’s preferred method for making a
              payment.</label
            >
            <mat-radio-group formControlName="billPaymentModeId">
              <mat-radio-button
                class="example-radio-button pt-1 pr-2"
                *ngFor="let mode of payments"
                [value]="mode.modeId"
              >
                {{ mode.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="pl-3 pt-3">
            <button mat-button matStepperPrevious class="backtbtn mr-3">
              BACK
            </button>
            <button
              mat-button
              matStepperNext
              class="nextbtn"
              (click)="onSubmit()"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Confirmation</ng-template>
        <p>
          <img
            src="../../../assets/icons/svg/confirmation.png"
            class="customers-image"
            height="40"
          />
          <span class="confirm-lable">Confirmation</span>
        </p>
        <p class="lable-color py-2">Thank you!</p>
        <p class="lable-color py-2">
          Your information is ready to be reviewed by our team.
        </p>

        <p class="py-2">
          You will receive an email notification when risk assessment is
          completed.
        </p>

        <p class="py-2">
          <span class="lable-color"> *Questions?</span> Contact support Monday
          -Friday, 9:00am - 6:30pm ET at 1-888-772-4080 or email
          <a href="mailto: PaySupport@truckstop.com">
            PaySupport@truckstop.com</a
          >
        </p>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
