import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { ShipperSearch } from '../models/customers.model';
import { ShipperContacts } from '../models/customers.model';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private _api: ApiService) {}

  getShipperCounts(): Observable<any> {
    return this._api.get('/truckstopprime/v1/shipper/metadata');
  }

  getShipperSearch(
    value: string,
    dtp: number,
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    let data;
    if (!dtp) {
      data = [
        {
          name: 'Name',
          value: value,
          ValueDataType: 'string',
          operator: 'contains',
          LogicalOperator: 'or',
        },
      ];
    } else {
      data = [
        {
          name: 'Name',
          value: value,
          ValueDataType: 'string',
          operator: 'contains',
          LogicalOperator: 'and',
        },
        {
          name: 'DaysToPay',
          value: dtp,
          operator: 'Equal',
          LogicalOperator: 'and',
        },
      ];
    }

    const _searchFilter: ShipperSearch = {
      pagination: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
      searchCriteria: data,
      sortCriteria: [
        {
          name: 'Name',
          direction: 0,
        },
      ],
    };
    return this._api.post('/truckstopprime/v1/shipper/search', _searchFilter);
  }

  createShipperContact(id: string, data: any): Observable<any> {
    const addContactData: ShipperContacts = {
      id: data.id,
      contactTypeName: data.contactTypeName,
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: data.fullName,
      email: data.email,
      phone: data.phone,
      ext: data.ext,
      mobile: data.mobile,
      isPrimary: data.isPrimary,
    };
    return this._api.post('/truckstopprime/v1/shipper/' + id, addContactData);
  }

  updateShipperContact(
    id: string,
    shipperContactId: string,
    updateShipperContact
  ): Observable<any> {
    return this._api.put(
      '/truckstopprime/v1/shipper/' +
        id +
        '/shipperContact/' +
        shipperContactId,
      updateShipperContact
    );
  }

  deleteShipperContact(
    shipperId: string,
    shipperContactId: string
  ): Observable<any> {
    return this._api.delete(
      '/truckstopprime/v1/shipperContact/' + shipperContactId
    );
  }
  getIndustrySuggestions(input: string): Observable<any> {
    return this._api.get(`/truckstopprime/v1/siccodes/autocomplete/${input}`);
  }
  createShipper(data): Observable<any> {
    return this._api.post(`/truckstopprime/v1/shipper`, data);
  }
  updateShipper(id: string, data: any): Observable<any> {
    return this._api.put('/truckstopprime/v1/shipper/' + id, data);
  }
}
