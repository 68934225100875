import {
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  FormGroup,
} from '@angular/forms';
import {
  Load,
  LoadRequestV2Contract,
  LocationAddress,
  LoadStop,
} from './newload.model';
import { debounceTime } from 'rxjs/operators';
import { LookupService } from '../lookup-service';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

export class NewLoadFormHelper {
  constructor(private fb: FormBuilder, private lookupService: LookupService) {}

  createLoadForm(load: Load) {
    const loadForm = this.fb.group({
      shipperRate: new FormControl(load.shipperRate, [Validators.required]),
      shipperId: new FormControl(load.shipperId, [Validators.required]),
      shipperContactId: new FormControl(load.shipperContactId, [
        Validators.required,
      ]),
      loadRequestV2Contract: this.createLoadRequestV2Contract(
        load.loadRequestV2Contract
      ),
    });
    return loadForm;
  }
  createLoadRequestV2Contract(loadRequest: LoadRequestV2Contract) {
    const loadRequestForm = this.fb.group({
      commodityId: new FormControl(loadRequest.commodityId),
      dimensional: this.fb.group({
        cube: new FormControl(loadRequest.dimensional.cube),
        height: new FormControl(loadRequest.dimensional.height),
        length: new FormControl(loadRequest.dimensional.length),
        palletCount: new FormControl(loadRequest.dimensional.palletCount),
        pieceCount: new FormControl(loadRequest.dimensional.pieceCount),
        width: new FormControl(loadRequest.dimensional.width),
        weight: new FormControl(loadRequest.dimensional.weight),
      }),
      distance: new FormControl(loadRequest.distance),
      equipmentAttributes: this.fb.group({
        equipmentTypeId: new FormControl(
          loadRequest.equipmentAttributes.equipmentTypeId
        ),
        equipmentOptions: loadRequest.equipmentAttributes.transportationModeId,
        transportationModeId:
          loadRequest.equipmentAttributes.transportationModeId,
        otherEquipmentNeeds:
          loadRequest.equipmentAttributes.transportationModeId,
      }),
      freightClassId: new FormControl(loadRequest.freightClassId),
      loadActionAttributes: this.fb.group({
        loadActionId: loadRequest.loadActionAttributes.loadActionId,
        // loadActionOption: loadRequest.loadActionAttributes.loadActionOption,
        loadActionOption: 'PostOnlineTender',
      }),
      loadLabel: new FormControl(loadRequest.loadLabel),
      loadNumber: new FormControl(loadRequest.loadNumber),
      loadReferenceNumbers: new FormControl(
        loadRequest.loadReferenceNumbers || []
      ),
      loadStops: new FormArray(
        loadRequest.loadStops.map((x) => this.createLoadStopForm(x))
      ),
      tenderNotes: new FormControl(loadRequest.tenderNotes),
      termsAndConditions: this.fb.group({
        id: loadRequest.termsAndConditions.id,
      }),
      loadTrackingRequired: new FormControl(
        loadRequest.loadTrackingRequired || false
      ),
      note: new FormControl(loadRequest.note),
      postAsUserId: new FormControl(loadRequest.postAsUserId),
      rateAttributes: this.fb.group({
        postedAllInRate: this.fb.group({
          amount: loadRequest.rateAttributes.postedAllInRate.amount,
          currencyCode:
            loadRequest.rateAttributes.postedAllInRate.currencyCode || 'USD',
        }),
        tenderAllInRate: this.fb.group({
          amount: loadRequest.rateAttributes.tenderAllInRate.amount,
          currencyCode:
            loadRequest.rateAttributes.tenderAllInRate.currencyCode || 'USD',
        }),
      }),
      carrierTenderGroupIds: new FormControl(loadRequest.carrierTenderGroupIds),
      percentage: new FormControl(loadRequest.percentage),
    });
    return loadRequestForm;
  }
  createLocationForm(location: LocationAddress) {
    if (!location) {
      location = {} as LocationAddress;
    }
    const locationForm = this.fb.group({
      city: location.city,
      countryCode: location.countryCode,
      postalCode: location.postalCode,
      state: location.state,
      streetAddress1: location.streetAddress1,
      streetAddress2: location.streetAddress2,
      locationName: new FormControl(location.locationName, [
        Validators.required,
      ]),
    });
    this.createAddress(locationForm);
    return locationForm;
  }
  createAddress(locationForm: FormGroup) {
    const location = locationForm.value as LocationAddress;
    let address = '';
    if (location.streetAddress1) {
      address += location.streetAddress1;
    }
    if (location.streetAddress2) {
      address += `, ${location.streetAddress2}`;
    }
    if (location.city) {
      address += `, ${location.city}`;
    }
    if (location.state) {
      address += `, ${location.state}`;
    }
    if (location.countryCode) {
      address += `, ${location.countryCode}`;
    }
    const addressFc = new FormControl(address, [Validators.required]);
    // addressFc.valueChanges.pipe(debounceTime(350)).subscribe((val) => {
    //     this.addressOptions = this.lookupService.LookupAddress(val);
    // });
    locationForm.addControl('address', addressFc);
  }
  createLoadStopForm(stop: LoadStop) {
    const fg = this.fb.group({
      location: this.createLocationForm(stop.location),
      contactName: new FormControl(stop.contactName),
      contactPhone: new FormControl(stop.contactPhone),
      earlyDateTime: new FormControl(new Date(stop.earlyDateTime), [
        Validators.required,
      ]),
      lateDateTime: new FormControl(new Date(stop.lateDateTime), [
        Validators.required,
      ]),
      stopNotes: new FormControl(stop.stopNotes),
      referenceNumbers: new FormControl(stop.referenceNumbers || []),
      type: stop.type,
      required: stop.required,
    });
    const earlyDateTime = new Date(stop.earlyDateTime);
    const earlyDate = new FormControl(earlyDateTime.getDate(), [
      Validators.required,
    ]);
    const earlyTime = new FormControl(earlyDateTime.getTime());
    earlyDate.valueChanges.subscribe((value) => {
      console.log(value, 'date');
      const earlyDateTimeControl = fg.get('earlyDateTime');
      const dateValue = this.UpdateDate(earlyDateTimeControl.value, value);
      earlyDateTimeControl.setValue(dateValue);
    });
    earlyTime.valueChanges.subscribe((value: string) => {
      console.log(value, 'time');
      const earlyDateTimeControl = fg.get('earlyDateTime');
      const dateValue = this.UpdateTime(earlyDateTimeControl.value, value);
      earlyDateTimeControl.setValue(dateValue);
    });
    const lateDateTime = new Date(stop.lateDateTime);
    const lateDate = new FormControl(lateDateTime.getDate(), [
      Validators.required,
    ]);
    const lateTime = new FormControl(lateDateTime.getTime());
    lateDate.valueChanges.subscribe((value) => {
      const lateDateTimeControl = fg.get('lateDateTime');
      const dateValue = this.UpdateDate(lateDateTimeControl.value, value);
      lateDateTimeControl.setValue(dateValue);
    });
    lateTime.valueChanges.subscribe((value) => {
      const lateDateTimeControl = fg.get('lateDateTime');
      const dateValue = this.UpdateTime(lateDateTimeControl.value, value);
      lateDateTimeControl.setValue(dateValue);
    });

    fg.addControl('earlyDate', earlyDate);
    fg.addControl('earlyTime', earlyTime);
    fg.addControl('lateDate', lateDate);
    fg.addControl('lateTime', lateTime);
    return fg;
  }

  public UpdateDate(dateValue: Date, value: Date) {
    if (isNaN(dateValue.getTime())) {
      dateValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    dateValue.setDate(value.getDate());
    dateValue.setMonth(value.getMonth());
    dateValue.setFullYear(value.getFullYear());
    return dateValue;
  }
  public UpdateTime(dateValue: Date, value: string) {
    console.log(value);
    if (isNaN(dateValue.getTime())) {
      dateValue = new Date();
    }
    // time is in 24 hour format
    const [hour, minutes] = value.split(':');
    dateValue.setHours(Number.parseInt(hour, 10));
    dateValue.setMinutes(Number.parseInt(minutes, 10));
    return dateValue;
  }
}
