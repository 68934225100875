<div class="left-menu" [ngClass]="{ expanded: opened, 'icon-only': !opened }">
  <mat-toolbar>
    <mat-toolbar-row class="toolbar-menu">
      <div class="menu-header">
        <img
          *ngIf="opened"
          src="../../../assets/icons/svg/truckstop-prime-logo.svg"
          class="profile-image"
          alt="logo"
        />
        <img
          *ngIf="!opened"
          src="../../../assets/icons/svg/ts-prime-logo-icon-only.svg"
          class="profile-image"
          alt="logo"
        />
      </div>
      <div class="menu-toggle" (click)="toggle()">
        <i
          *ngIf="opened"
          class="fa fa-caret-left arrow-icon"
          aria-hidden="true"
        ></i>
        <i
          *ngIf="!opened"
          class="fa fa-caret-right arrow-icon"
          aria-hidden="true"
        ></i>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="menu-ui mt-3">
    <div class="pl-3 my-2 py-2 btn menu-list w-100">
      <ts-icon
        name="home"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>
      <span *ngIf="opened">Home</span>
    </div>
    <div class="pl-3 py-2 my-2 btn menu-list w-100" routerLink="/app/customer">
      <ts-icon
        name="user-people-2"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>
      <!-- <img src="../../../assets/icons/svg/customers.svg" height="15" class="mr-3"> -->

      <span *ngIf="opened">Customers</span>
    </div>
    <div class="pl-3 py-2 my-2 btn menu-list w-100" routerLink="/app/loads">
      <ts-icon
        name="equipment-trailer-van"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>
      <span *ngIf="opened">Loads</span>
    </div>
    <div
      class="pl-3 py-2 my-2 btn menu-list w-100"
      (click)="redirect('alerts')"
    >
      <ts-icon
        name="notifications"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>

      <span *ngIf="opened">Alerts</span>
    </div>
    <div
      class="pl-3 py-2 my-2 btn menu-list w-100"
      (click)="redirect('reports')"
    >
      <ts-icon
        name="usd-circle"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>
      <span *ngIf="opened">Reports</span>
    </div>
    <div
      class="pl-3 py-2 my-2 btn menu-list w-100"
      (click)="redirect('leaderboard')"
    >
      <ts-icon name="leaderboard" class="mr-3"></ts-icon>
      <span *ngIf="opened">Leaderboard</span>
    </div>
    <div class="pl-3 py-2 my-2 btn menu-list" routerLink="/app/settings">
      <ts-icon
        name="settings"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>

      <span *ngIf="opened">Settings</span>
    </div>
    <div
      class="pl-3 py-2 my-2 btn menu-list w-100"
      (click)="redirect('support')"
    >
      <ts-icon
        name="online-chat-support"
        class="mr-3"
        [ngClass]="{ 'collpased-menu': !opened }"
      ></ts-icon>
      <span *ngIf="opened">Support</span>
    </div>
  </div>
</div>
<mat-toolbar>
  <mat-toolbar-row class="toolbar">
    <div class="container">
      <div class="row">
        <div class="col-9">
          <div>
            <input
              type="text"
              class="form-control search"
              placeholder="Search"
            />
          </div>
        </div>
        <div class="col-1 notifications-container">
          <ts-icon name="notifications" class="notifications"></ts-icon>
          <span class="badge badge-danger">8</span>
        </div>
        <div class="col-2 v1">
          <div class="profile-main">
            <div class="dropdown show">
              <a
                class="btn btn-secondary dropdown-toggle profile-dropdown"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="../../../assets/icons/svg/Jim-Vrtis.jpg"
                  class="profile-image"
                  alt="Cinque Terre"
                />
                <span>Jim Vrtis</span>
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" routerLink="/app/profile"
                  ><img src="../../../assets/icons/svg/profile.svg" />
                  <span>My Profile</span></a
                >
                <a class="dropdown-item"
                  ><img src="../../../assets/icons/svg/help.svg" />
                  <span>Help</span></a
                >
                <a class="dropdown-item"
                  ><img src="../../../assets/icons/svg/online-support.svg" />
                  <span>Online Support</span></a
                >
                <a class="dropdown-item" routerLink="/login"
                  ><img src="../../../assets/icons/svg/logout.svg" />
                  <span>Logout</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="right-panel" [ngClass]="{ min: opened, max: !opened }">
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
