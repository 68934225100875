import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'prime-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoritesComponent implements OnInit {
  constructor() {}
  public FAVORITES_TABLE_DATA: any = [
    {
      customer: 'Customer',
      pickup: 'Pickup',
      delivery: 'Delivery',
      equipment: 'equipment',
      actions: 'Actions',
    },
    {
      customer: 'Customer',
      pickup: 'Pickup',
      delivery: 'Delivery',
      equipment: 'equipment',
      actions: 'Actions',
    },
  ];
  displayedColumns: any[] = [
    'customer',
    'pickup',
    'delivery',
    'equipment',
    'actions',
  ];
  dataSource = this.FAVORITES_TABLE_DATA;

  ngOnInit(): void {}
}
