<div class="load_content p-4">
  <span class="heading_2 p-2">Loads ({{ loadData.length }})</span>
  <hr />
  <div class="d-flex justify-content-around p-2">
    <div class="d-flex flex-column">
      <span class="heading_4">Filter</span>
      <div class="d-flex align-items-center">
        <!-- <button mat-button class="width-100 mr-2">
          <mat-select [formControl]="optionControl" required>
            <mat-option *ngFor="let Options of Options" [value]="Options">
              {{ Options.name }}</mat-option
            >
          </mat-select>
        </button> -->
        <button mat-button *ngIf="!ShowFilters" (click)="ShowFilters = true">
          <ts-icon name="filter"></ts-icon>
        </button>
        <button mat-button *ngIf="ShowFilters" (click)="ShowFilters = false">
          <ts-icon name="close"></ts-icon>
        </button>
      </div>
    </div>
    <div class="vertical-divider"></div>
    <div class="d-flex flex-column">
      <span class="heading_4">Load Status</span>
      <div>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.All"
          (click)="onStatusChange(status.All)"
        >
          All
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Sourcing"
          (click)="onStatusChange(status.Sourcing)"
        >
          Sourcing
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Booked"
          (click)="onStatusChange(status.Booked)"
        >
          Booked
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.ScheduledForPickup"
          (click)="onStatusChange(status.ScheduledForPickup)"
        >
          Scheduled For Pickup
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.OutForDelivery"
          (click)="onStatusChange(status.OutForDelivery)"
        >
          Out For Delivery
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Delayed"
          (click)="onStatusChange(status.Delayed)"
        >
          Delayed
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Revoked"
          (click)="onStatusChange(status.Revoked)"
        >
          Revoked
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Dropped"
          (click)="onStatusChange(status.Dropped)"
        >
          Dropped
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Expired"
          (click)="onStatusChange(status.Expired)"
        >
          Expired
        </button>
        <button
          mat-button
          class="toggle-button heading_3"
          [class.active]="loadStatus === status.Draft"
          (click)="onStatusChange(status.Draft)"
        >
          Draft
        </button>
      </div>
    </div>
    <div class="vertical-divider"></div>
    <div class="d-flex flex-column">
      <span class="heading_4">Search</span>
      <div class="d-flex align-items-center">
        <input
          matInput
          placeholder="Search"
          type="text"
          class="ts-input width-100 mr-2"
        />
        <button mat-button [matMenuTriggerFor]="menu">
          <ts-icon name="list-view"></ts-icon>
        </button>
        <mat-menu #menu>
          <button mat-menu-item>
            <div class="d-flex align-items-center">
              <ts-icon name="list-view" class="p-2"></ts-icon>List View
            </div>
          </button>
          <button mat-menu-item>
            <div class="d-flex align-items-center">
              <ts-icon name="action-view" class="p-2"></ts-icon>
              Action View
            </div>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="d-flex pt-4" *ngIf="ShowFilters">
    <div class="d-inline-flex flex-column pr-3">
      <span>Pickup Date </span>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <!-- <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" />
          <input matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker> -->
      </mat-form-field>
    </div>
    <div class="vertical-divider mr-5 ml-5"></div>
    <div class="d-inline-flex flex-column pr-3">
      <span>Deliver Date </span>
      <mat-form-field [floatLabel]="'never'">
        <input matInput [matDatepicker]="picker1" />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <!-- <mat-date-range-input [rangePicker]="picker1">
          <input
            matStartDate
            [formControl]="listForm.get('PickupStart')"
            placeholder="Start date"
          />
          <input
            matEndDate
            [formControl]="listForm.get('PickupEnd')"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker> -->
      </mat-form-field>
    </div>
  </div>
  <div class="flex pt-2" *ngIf="ShowFilters">
    <div class="d-flex">
      <div class="d-inline-flex flex-column pr-2">
        <span>Customers</span>
        <input
          type="text"
          class="ts-input"
          [formControl]="listForm.get('customer')"
          placeholder="Search Customers"
        />
      </div>
      <div class="d-flex flex-column">
        <span class="heading_4">Top 5 Customers</span>
        <div>
          <button
            mat-button
            class="toggle-button heading_3"
            [class.active]="loadStatus === status.All"
            (click)="onStatusChange(status.All)"
          >
            All
          </button>
          <button
            mat-button
            class="toggle-button heading_3"
            [class.active]="loadStatus === status.Sourcing"
            (click)="onStatusChange(status.Sourcing)"
          >
            Smith Door Distributinginc
          </button>
          <button
            mat-button
            class="toggle-button heading_3"
            [class.active]="loadStatus === status.Booked"
            (click)="onStatusChange(status.Booked)"
          >
            Tullos Auto Salvage
          </button>
          <button
            mat-button
            class="toggle-button heading_3"
            [class.active]="loadStatus === status.ScheduledForPickup"
            (click)="onStatusChange(status.ScheduledForPickup)"
          >
            Asco Hardware Co
          </button>
          <button
            mat-button
            class="toggle-button heading_3"
            [class.active]="loadStatus === status.OutForDelivery"
            (click)="onStatusChange(status.OutForDelivery)"
          >
            Steel Sales &Equi
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-4">
    <ag-grid-angular
      style="width: 100%; height: 500px;"
      class="ag-theme-balham"
      [rowData]="loadData"
      [columnDefs]="columnDefs"
      [pagination]="true"
      [paginationPageSize]="10"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>
</div>
