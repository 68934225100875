import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomersService } from '../../core/customer.service';
export interface ConfirmDialogModel {
  shipperId: any;
  data: any;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  submessage: string;
  shipperId: any;
  shipperContacts: any;
  shipperContactId: any;
  deletedShipperContact: any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _customerService: CustomersService
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.submessage = data.submessage;
    // this.deletedShipperContact = { ...data };
  }

  ngOnInit() {
    console.log(this.data);
    this.shipperId = this.data.shipperId;
    this.shipperContactId = this.data.data.id;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
    this._customerService
      .deleteShipperContact(this.shipperId, this.shipperContactId)
      .subscribe(
        (res) => {
          if (res) {
            console.log(res);
            this.deletedShipperContact = res;
            this.dialogRef.close(this.deletedShipperContact);
            // if(res!=="2")
            // }
          }
        },
        (e) => {
          console.log(e);
        }
      );
  }

  onClose(): void {
    // Close the dialog, return false
    this.dialogRef.close(true);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public submessage: string
  ) {}
}
