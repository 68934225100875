import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prime-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
