import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { CoreModule } from './core.module';
import { AuthService } from './auth.service';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { LoginService } from './login.service';
@Injectable({
  providedIn: CoreModule,
})
export class TokenInterceptor implements HttpInterceptor {
  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private _authService: AuthService,
    private _loginService: LoginService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this._authService.isSignedIn()) {
      const { accessToken } = this._authService.getTokenInfo();
      if (accessToken) {
        request = this.addTokenHeader(request, accessToken);
      }
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, accessToken: any) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._loginService.renewToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          const jwt = this._authService.getTokenInfo();
          this.refreshTokenSubject.next(jwt);
          return next.handle(this.addTokenHeader(request, jwt.accessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((jwt) => jwt !== null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addTokenHeader(request, jwt.accessToken));
        })
      );
    }
  }
}
