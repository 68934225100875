import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CustomersService } from '../../core/customer.service';
import { ShipperData, ShipperContacts } from '../../models/customers.model';

@Component({
  selector: 'prime-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCustomerComponent implements OnInit {
  bussinessFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  contactsFormGroup: FormGroup;
  billingFormGroup: FormGroup;
  filteredIndustry: [];
  states: [];
  country: string[] = ['USA', 'CAN'];
  options = [{ name: 'USA' }, { name: 'CAN' }];
  contactType = [
    { name: 'Primary', value: 1 },
    { name: 'Secendary', sound: 2 },
  ];
  payments: any = [
    { modeId: 1, name: 'ACH' },
    { modeId: 2, name: 'CHECK' },
    { modeId: 3, name: 'Wire' },
    { modeId: 4, name: 'Credit Card' },
  ];
  public shipperData: ShipperData = <ShipperData>{};
  filteredOptions: Observable<any[]>;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _customerService: CustomersService
  ) {}

  ngOnInit(): void {
    this.shipperData.shipperContacts = <ShipperData['shipperContacts']>[];

    this.contactsFormGroup = this.formBuilder.group({
      contactsArray: this.formBuilder.array([this.createContactFormGroup()]),
    });

    this.bussinessFormGroup = this.formBuilder.group({
      companyName: ['', [Validators.required]],
      businessType: [''],
      industry: [],
      companyId: [''],
      countryOfIncorporation: [''],
      stateOfIncorporation: [''],
      website: [''],
    });
    this.addressFormGroup = this.formBuilder.group({
      mainZipCode: [],
      mainAddress1: [],
      mainCity: [],
      mainCountryCode: [],
      mainAddress2: [],
      mainStateCode: [],
      billingZipCode: [],
      billingAddress1: [],
      billingCity: [],
      billingCountryCode: [],
      billingAddress2: [],
      billingStateCode: [],
    });
    // this.contactsFormGroup = this.formBuilder.group({
    //   contactType: [],
    //   firstName: [],
    //   lastName: [],
    //   phone: [],
    //   ext: [],
    //   mobile: [],
    //   email: [],
    // });
    this.billingFormGroup = this.formBuilder.group({
      isOriginalBillRequired: [false],
      email: [],
      usMail: [],
      billPaymentModeId: [],
    });
  }
  toggle(event) {
    console.log(event);
  }
  checkBillingAddress(event) {
    if (event) {
      this.addressFormGroup
        .get('billingAddress1')
        .patchValue(this.addressFormGroup.value.mainAddress1);
      this.addressFormGroup
        .get('billingAddress2')
        .patchValue(this.addressFormGroup.value.mainAddress2);
      this.addressFormGroup
        .get('billingCity')
        .patchValue(this.addressFormGroup.value.mainCity);
      this.addressFormGroup
        .get('billingCountryCode')
        .patchValue(this.addressFormGroup.value.mainCountryCode);
      this.addressFormGroup
        .get('billingZipCode')
        .patchValue(this.addressFormGroup.value.mainZipCode);
      this.addressFormGroup
        .get('billingStateCode')
        .patchValue(this.addressFormGroup.value.mainStateCode);

      // this.addressFormGroup.value.billingAddress1 = this.addressFormGroup.value.mainAddress1;
      // this.addressFormGroup.value.billingAddress2 = this.addressFormGroup.value.mainAddress2;
      // this.addressFormGroup.value.billingCity = this.addressFormGroup.value.mainCity;
      // this.addressFormGroup.value.billingCountryCode = this.addressFormGroup.value.mainCountryCode;
      // this.addressFormGroup.value.billingZipCode = this.addressFormGroup.value.mainZipCode;
      // this.addressFormGroup.value.billingStateCode = this.addressFormGroup.value.mainStateCode;
    } else {
      this.addressFormGroup.get('billingAddress1').patchValue('');
      this.addressFormGroup.get('billingAddress2').patchValue('');
      this.addressFormGroup.get('billingCity').patchValue('');
      this.addressFormGroup.get('billingCountryCode').patchValue('');
      this.addressFormGroup.get('billingZipCode').patchValue('');
      this.addressFormGroup.get('billingStateCode').patchValue('');
    }
  }
  private createContactFormGroup(): FormGroup {
    return new FormGroup({
      contactTypeName: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      ext: new FormControl(''),
      mobile: new FormControl(''),
      email: new FormControl(''),
      isPrimary: new FormControl(false),
    });
  }

  public addContactInput() {
    const contactsArray = this.contactsFormGroup.get(
      'contactsArray'
    ) as FormArray;
    contactsArray.push(this.createContactFormGroup());
    console.log(this.contactsFormGroup.get('contactsArray')['controls']);
  }

  public removeOrClearContact(i: number) {
    const contactsArray = this.contactsFormGroup.get(
      'contactsArray'
    ) as FormArray;
    if (contactsArray.length > 1) {
      contactsArray.removeAt(i);
    } else {
      contactsArray.reset();
    }
  }

  onBackButton() {
    this.router.navigate(['/app/customer']);
  }

  onSubmit() {
    console.log(this.bussinessFormGroup.value);
    console.log(this.addressFormGroup.value);
    console.log(this.contactsFormGroup.value);
    console.log(this.billingFormGroup.value);

    this.mapRequestBody(
      this.bussinessFormGroup.value,
      this.addressFormGroup.value,
      this.contactsFormGroup.value,
      this.billingFormGroup.value
    );
  }
  displayFn(user): string {
    return user && user.definition ? user.definition : '';
  }
  mapRequestBody(bussiness, address, contact, billing) {
    this.shipperData.name = bussiness.companyName;
    this.shipperData.businessType = bussiness.businessType;
    this.shipperData.siCcodeId =
      bussiness.industry && bussiness.industry.id ? bussiness.industry.id : 1;
    this.shipperData.ssn = bussiness.companyId;
    this.shipperData.daysToPay = 2;
    this.shipperData.countryOfIncorporation = bussiness.countryOfIncorporation;
    this.shipperData.stateOfIncorporation = bussiness.stateOfIncorporation;
    this.shipperData.website = bussiness.website;
    this.shipperData.mainAddress1 = address.mainAddress1;
    this.shipperData.mainAddress2 = address.mainAddress2;
    this.shipperData.mainCity = address.mainCity;
    this.shipperData.mainStateCode = address.mainStateCode;
    this.shipperData.mainZipCode = address.mainZipCode;
    this.shipperData.billingAddress1 = address.billingAddress1;
    this.shipperData.billingAddress2 = address.billingAddress2;
    this.shipperData.billingCity = address.billingCity;
    this.shipperData.billingStateCode = address.billingStateCode;
    this.shipperData.billingZipCode = address.billingZipCode
      ? address.billingZipCode
      : null;
    this.shipperData.billingEmail = billing.email;
    this.shipperData.billingUSMail = billing.usMail;
    this.shipperData.isOriginalBillRequired = billing.isOriginalBillRequired;
    this.shipperData.billPaymentMethodId =
      billing && billing.billPaymentModeId ? billing.billPaymentModeId : 1;
    const _contact: ShipperContacts = this.mapContacts(contact.contactsArray);
    this.shipperData.shipperContacts.push(_contact);
    this._createShipper();
  }
  private _createShipper() {
    this._customerService.createShipper(this.shipperData).subscribe(
      (res) => {
        if (res) {
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }
  doIndustrySearch(event) {
    const val = event.target.value;
    this._getIndustrySuggestion(val);
  }
  mapContacts(item) {
    const result: ShipperContacts = <ShipperContacts>{};

    item.forEach(function (value) {
      console.log(value);
      result.firstName = value.firstName;
      result.lastName = value.lastName;
      result.email = value.email;
      result.phone = value.phone;
      result.ext = value.ext;
      result.mobile = value.mobile;
      result.contactTypeName = value.contactTypeName;
      result.isPrimary = value.isPrimary;
    });

    return result;
  }
  private _getIndustrySuggestion(val: string) {
    if (val && val.trim() !== '' && val.length > 1) {
      this._customerService.getIndustrySuggestions(val).subscribe(
        (data) => {
          this.filteredIndustry = data ? data : [];
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }
}
