import { Time } from '@angular/common';

export interface Dimensional {
  cube?: any;
  height?: any;
  length?: any;
  palletCount: number;
  pieceCount: number;
  width?: any;
  weight: number;
}

export interface EquipmentAttributes {
  equipmentTypeId: number;
  equipmentOptions: any[];
  transportationModeId: number;
  otherEquipmentNeeds?: any;
}

export interface LoadActionAttributes {
  loadActionId: number;
  loadActionOption: string;
}

export interface LocationAddress {
  city: string;
  countryCode: string;
  postalCode: string;
  state: string;
  streetAddress1: string;
  streetAddress2: string;
  locationName: string;
}
export enum StopType {
  AddtionalStops,
  Origin,
  Destination,
}
export interface LoadStop {
  location: LocationAddress;
  contactName: string;
  contactPhone: string;
  earlyDateTime: Date;
  lateDateTime: Date;
  sequence: number;
  stopNotes: string;
  referenceNumbers: string[];
  type: StopType;
  // single origin and destination is always required
  required: boolean;
}

export interface TermsAndConditions {
  id: string;
  name: string;
}

export interface PostedAllInRate {
  amount: number;
  currencyCode: string;
}

export interface TenderAllInRate {
  amount: number;
  currencyCode: string;
}

export interface RateAttributes {
  postedAllInRate: PostedAllInRate;
  tenderAllInRate: TenderAllInRate;
}

export interface LoadRequestV2Contract {
  commodityId?: any;
  dimensional: Dimensional;
  distance: number;
  equipmentAttributes: EquipmentAttributes;
  freightClassId: number;
  loadActionAttributes: LoadActionAttributes;
  loadLabel: string;
  loadNumber: string;
  loadReferenceNumbers: string[];
  loadStops: LoadStop[];
  tenderNotes: string;
  termsAndConditions: TermsAndConditions;
  loadTrackingRequired: boolean;
  note: string;
  postAsUserId: string;
  rateAttributes: RateAttributes;
  carrierTenderGroupIds: string[];
  percentage: number;
}

export interface Load {
  shipperRate: number;
  shipperId: string;
  shipperContactId: string;
  loadRequestV2Contract: LoadRequestV2Contract;
}
