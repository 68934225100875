export enum LoadStatus {
  All,
  Sourcing,
  Booked,
  ScheduledForPickup,
  OutForDelivery,
  Delayed,
  Revoked,
  Dropped,
  Expired,
  Draft,
}
