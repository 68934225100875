<div class="load p-4">
  <div class="load_content p-2" *ngIf="load">
    <div class="row align-items-center sticky-top bg-white mt-4 mb-4">
      <div class="load_chart">
        <ts-chart
          class="hydrated"
          config="{{ progressChart.config }}"
          data="{{ progressChart.data }}"
        ></ts-chart>
      </div>
      <div class="load_tooltip loadpost-info-icon">
        <ts-tooltip class="hydrated" options="{{ tooltipOptions.help }}">
          <ts-icon class="load_tooltip_icon" name="help-circle"> </ts-icon>
        </ts-tooltip>
      </div>
    </div>
    <div class="row no-gutters flex-column form-group">
      <span class="heading_3 p-2">Customer</span>
      <div class="col-6">
        <mat-select (selectionChange)="onCustomerSelect($event)">
          <mat-option [value]="customer" *ngFor="let customer of customers">
            <span>{{ customer.name }}</span>
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="heading_3 align-self-center p-2">Origin</div>
    <ng-container
      *ngTemplateOutlet="origin; context: { $implicit: { form: origination } }"
    >
    </ng-container>

    <ng-container *ngFor="let additionalStop of additionalStops; let i = index">
      <div class="row no-gutters">
        <span class="form-group heading_3 p-2 align-self-center">{{
          'Stop ' + (i + 1)
        }}</span>
        <mat-divider [vertical]="true"></mat-divider>
        <button
          class="form-group col-md-2 col-12 p-2"
          id=""
          mat-button
          (click)="deleteAddtionalStop(additionalStop)"
        >
          Delete this Stop
        </button>
      </div>
      <ng-container
        *ngTemplateOutlet="
          origin;
          context: { $implicit: { form: additionalStop } }
        "
      >
      </ng-container>
    </ng-container>
    <div class="row no-gutters">
      <ts-button
        (click)="addAddtionalStop()"
        outlined
        class="secondary-with-color hover-focus-indicator-color col-12 col-md-6 col-lg-2 px-1 mb-2"
      >
        ADD A STOP
      </ts-button>
    </div>

    <div class="heading_3 align-self-center p-2">Destination</div>
    <ng-container
      *ngTemplateOutlet="origin; context: { $implicit: { form: destination } }"
    >
    </ng-container>
    <div class="row no-gutters">
      <div class="row col-md-4 col-12 d-flex justify-content-around">
        <div class="form-group col-md-6 col-12">
          <span class="heading_4" for="equipment">Equipment*</span>
          <mat-select
            [formControl]="Equipment.get('equipmentTypeId')"
            [class.error-border]="Validate(Equipment, 'equipmentTypeId')"
          >
            <mat-option
              [value]="equipment.id"
              *ngFor="let equipment of equipmentTypes"
            >
              <span>{{ equipment.description }}</span>
            </mat-option>
          </mat-select>
          <span class="error" *ngIf="Validate(Equipment, 'equipmentTypeId')"
            >Equipment is required</span
          >
        </div>
        <div class="form-group col-md-6 col-12">
          <span for="all_in_sourcing">All-in Sourcing Rate*</span>
          <input
            id="all_in_sourcing"
            class="form-control form-control-sm"
            type="number"
            [value]="RateAttributes.get('postedAllInRate').get('amount').value"
            [formControl]="RateAttributes.get('postedAllInRate').get('amount')"
          />
          <span
            class="error"
            *ngIf="Validate(RateAttributes.get('postedAllInRate'), 'amount')"
            >All-in-SourcingRate is required</span
          >
        </div>
        <div class="col-12 p-0">
          <ts-button
            outlined
            (click)="LookupRate()"
            class="secondary-with-color hover-focus-indicator-color w-100"
          >
            LOOKUP POSTED RATES</ts-button
          >
        </div>
      </div>
      <div
        class="row col-md-4 col-12 d-flex justify-content-around"
        *ngIf="rateChartData"
      >
        <div style="color: red;" *ngIf="rateChartData.error">
          {{ rateChartData.error }}
        </div>
        <ts-chart-rates
          class="chart-wrapper"
          data="{{ rateChartData.data }}"
        ></ts-chart-rates>
      </div>
      <div class="row col-md-4 col-12 d-flex justify-content-around">
        <div class="form-group col-md-6 col-12">
          <span class="heading_4" for="load_label">Load Label</span>
          <input
            type="load_label"
            id="load_label"
            [formControl]="loadForm.get('loadLabel')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group col-md-6 col-12">
          <span class="heading_4" for="terms&conditions"
            >Terms & Conditions*</span
          >
          <mat-select
            [formControl]="TermsAndConditions.get('id')"
            [class.error-border]="Validate(TermsAndConditions, 'id')"
          >
            <mat-option [value]="term.id" *ngFor="let term of terms">
              <span>{{ term.name }}</span>
            </mat-option>
          </mat-select>
          <span class="error" *ngIf="Validate(TermsAndConditions, 'id')"
            >Terms & Conditions is required</span
          >
        </div>
        <div class="form-group col-md-6 col-12">
          <span class="heading_4" for="load_value">Load Value</span>
          <input
            type="load_value"
            id="load_value"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group col-md-6 col-12">
          <span class="heading_4" for="load_reference">Load Reference*</span>
          <div class="d-flex justify-content-around align-items-center">
            <input
              #loadreference
              type="numbers"
              id="load_reference"
              class="form-control form-control-sm"
            />
            <ts-icon
              name="plus"
              class="p-2"
              (click)="
                addReference(
                  loadForm.get('loadReferenceNumbers'),
                  loadreference
                )
              "
            ></ts-icon>
            <mat-chip-list>
              <mat-chip
                *ngFor="
                  let reference of loadForm.get('loadReferenceNumbers').value;
                  let i = index
                "
                [removable]="true"
                (removed)="
                  deleteReference(loadForm.get('loadReferenceNumbers'), i)
                "
              >
                {{ reference }}
                <ts-icon matChipRemove *ngIf="true" name="close"></ts-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <span class="error" *ngIf="Validate(loadForm, 'loadReferenceNumbers')"
            >LoadReference Numbers is required</span
          >
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="form-group col-md-1 pr-1 col-12">
        <span class="heading_4" for="mode">Mode*</span>
        <mat-select
          [formControl]="Equipment.get('transportationModeId')"
          [class.error-border]="Validate(Equipment, 'transportationModeId')"
        >
          <mat-option [value]="mode.modeId" *ngFor="let mode of modes">
            <span>{{ mode.modeDescription }}</span>
          </mat-option>
        </mat-select>
        <span class="error" *ngIf="Validate(Equipment, 'transportationModeId')"
          >Mode is required</span
        >
      </div>
      <div class="form-group col-md-1 col-12">
        <span class="heading_4" for="weight">Weight*</span>
        <input
          type="number"
          placeholder="lbs"
          id="weight"
          [formControl]="Dimensions.get('weight')"
          class="form-control form-control-sm"
        />
        <span class="error" *ngIf="Validate(Dimensions, 'weight')"
          >Weight is required</span
        >
      </div>
      <div class="form-group col-md-2 col-12">
        <span class="heading_4" for="commodity">Commodity</span>
        <mat-select
          [formControl]="loadForm.get('commodityId')"
          [class.error-border]="Validate(loadForm, 'commodityId')"
        >
          <mat-option [value]="c.id" *ngFor="let c of commodity">
            <span>{{ c.description }}</span>
          </mat-option>
        </mat-select>
      </div>

      <div
        class="form-group col-md-4 col-12 d-flex flex-no-wrap justify-content-around"
      >
        <div class="form-group pr-1">
          <span class="heading_4" for="length">Length</span>
          <input
            type="number"
            id="length"
            [formControl]="Dimensions.get('length')"
            placeholder="ft."
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="width">Width</span>
          <input
            type="number"
            id="width"
            placeholder="ft."
            [formControl]="Dimensions.get('width')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="height">Height</span>
          <input
            type="number"
            id="height"
            placeholder="ft."
            [formControl]="Dimensions.get('height')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="cube">Cube</span>
          <input
            type="number"
            id="cube"
            placeholder="cu.ft."
            [formControl]="Dimensions.get('cube')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="pieces">Pieces</span>
          <input
            type="number"
            id="pieces"
            placeholder="0"
            [formControl]="Dimensions.get('pieceCount')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="pallets">Palletes</span>
          <input
            type="number"
            id="pallets"
            placeholder="0"
            [formControl]="Dimensions.get('palletCount')"
            class="form-control form-control-sm"
          />
        </div>
        <div class="form-group pr-1">
          <span class="heading_4" for="distance">Distance</span>
          <input
            type="number"
            id="distance"
            placeholder="mi."
            [formControl]="loadForm.get('distance')"
            class="form-control form-control-sm"
          />
        </div>
      </div>
      <div class="form-group col-md-4 col-12">
        <span class="heading_4" for="moreload_options">MoreLoad Options</span>
        <mat-select
          [formControl]="Equipment.get('equipmentOptions')"
          multiple
          [class.error-border]="Validate(Equipment, 'equipmentOptions')"
        >
          <mat-option
            [value]="option.id"
            *ngFor="let option of equipmentOptions"
          >
            <span>{{ option.description }}</span>
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="form-group col-md-2 col-12">
        <span class="heading_4" for="load_number">Load Number*</span>
        <input
          type="number"
          id="load_number"
          [formControl]="loadForm.get('loadNumber')"
          class="form-control form-control-sm"
        />
        <span class="error" *ngIf="Validate(loadForm, 'loadNumber')"
          >LoadNumber is required</span
        >
      </div>
      <div class="form-group col-md-2 col-12">
        <span class="heading_4" for="other_equipment_needs"
          >Other Equipment Needs</span
        >
        <input
          type="number"
          id="weight"
          class="form-control form-control-sm"
          [formControl]="Equipment.get('otherEquipmentNeeds')"
        />
      </div>
      <div
        class="form-group col-md-4 col-12 d-flex align-items-center justify-content-around custom-control custom-checkbox"
      >
        <mat-checkbox
          class="heading_4"
          [formControl]="loadForm.get('loadTrackingRequired')"
          >load Tracking Required
        </mat-checkbox>
      </div>
      <div class="form-group col-md-4 col-12">
        <span class="heading_4" for="post_as">Post As</span>
        <mat-select
          [formControl]="loadForm.get('postAsUserId')"
          [class.error-border]="Validate(loadForm, 'postAsUserId')"
        >
          <mat-option [value]="option.id" *ngFor="let option of postAs">
            <span>{{ option.firstName }}</span>
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="form-group col-md-2 col-12">
        <span class="heading_4" for="shipping_rates">Shipping Rates*</span>
        <input
          type="rate"
          id="shipping_rates"
          class="form-control form-control-sm"
        />
        <!-- <span class="error" *ngIf="Validate(loadForm, 'ShippingRates')"
          >Shipping Rates is required</span
        > -->
      </div>
      <div class="form-group col-md-2 col-12">
        <span class="heading_4" for="carrier_rates">Carrier Rates*</span>
        <input
          class="form-control form-control-sm"
          type="number"
          [value]="RateAttributes.get('tenderAllInRate').get('amount').value"
          [formControl]="RateAttributes.get('tenderAllInRate').get('amount')"
        />
        <span
          class="error"
          *ngIf="Validate(RateAttributes.get('tenderAllInRate'), 'amount')"
          >Carrier Rates is required</span
        >
      </div>
    </div>
    <div class="row no-gutters">
      <div class="form-group col-md-4 col-12">
        <div class="d-flex position-relative">
          <span class="heading_4" for="special_instrcutions"
            >Special Instructions</span
          >
          <ts-tooltip
            class="pl-1 hydrated"
            options="{{ tooltipOptions.specialInformation }}"
          >
            <ts-icon class="loadpost-info-icon" name="information-circle">
            </ts-icon>
          </ts-tooltip>
        </div>
        <textarea
          class="form-control"
          row
          no-gutters
          s="1"
          id="special_instrcutions"
        ></textarea>
      </div>
      <div class="form-group col-md-4 col-12">
        <div class="d-flex position-relative">
          <span class="heading_4" for="rate_confirmation_notes"
            >Rate Confirmation Notes</span
          >
          <ts-tooltip
            class="pl-1 hydrated"
            options="{{ tooltipOptions.rateConfirmationNotes }}"
          >
            <ts-icon class="loadpost-info-icon" name="information-circle">
            </ts-icon>
          </ts-tooltip>
        </div>
        <textarea class="form-control" row no-gutters s="1"></textarea>
      </div>
      <div class="form-group col-md-4 col-12">
        <div class="d-flex position-relative">
          <span class="heading_4" for="book_it_now_carrier_groups"
            >Book It Now Carrier Groups</span
          >
          <ts-tooltip
            class="pl-1 hydrated"
            options="{{ tooltipOptions.BookItNow }}"
          >
            <ts-icon class="loadpost-info-icon" name="information-circle">
            </ts-icon>
          </ts-tooltip>
        </div>
        <textarea
          class="form-control"
          row
          no-gutters
          s="1"
          id="book_it_now_carrier_groups"
        ></textarea>
      </div>
    </div>
    <span class="heading_4">Minimum Carrier Rating*:</span>
    <div class="row p-2">
      <ngb-rating [(rate)]="currentRate">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </div>
    <div class="d-flex pt-4 pt-2">
      <img
        class="cargo-insurance-img align-self-end"
        src="https://webcomponents-qa.truckstopprivate.net/build/ts-components/../../assets/cargo-insurance.svg"
        alt="shield-logo"
      />
      <a
        class="pl-1 bin-insurance-link pl-2"
        href="https://truckstop.com/product/insurance/cargo-insurance/"
        target="_blank"
        >Click here to insure this load with Cargo Insurance</a
      >
    </div>

    <mat-divider></mat-divider>
    <div class="d-flex align-items-center justify-content-center">
      <div class="mdc-layout-grid__cell pr-2">
        <ts-button
          outlined
          class="secondary-with-color hover-focus-indicator-color"
        >
          EXIT DRAFT WITHOUT SAVING</ts-button
        >
      </div>
      <div class="mdc-layout-grid__cell pr-2">
        <ts-button
          outlined
          (click)="SaveDraft()"
          class="secondary-with-color hover-focus-indicator-color"
          >SAVE & CLOSE DRAFT
        </ts-button>
      </div>
      <div class="mdc-layout-grid__cell pr-2">
        <ts-button
          outlined
          class="secondary-with-color hover-focus-indicator-color"
          >SOURCE TO PREFERRED CARRIER
        </ts-button>
      </div>
      <div class="mdc-layout-grid__cell pr-2">
        <ts-button
          outlined
          (click)="Source()"
          class="secondary-with-color hover-focus-indicator-color"
          >SOURCE
        </ts-button>
      </div>
    </div>
  </div>
</div>

<ng-template #origin let-data>
  <div class="row">
    <div class="form-group col-md-2 col-12">
      <span class="heading_4" for="locationname">Location Name*</span>
      <input
        type="name"
        [class.error-border]="
          Validate(data.form.get('location'), 'locationName')
        "
        [formControl]="data.form.get('location').get('locationName')"
        id="locationname"
        class="form-control form-control-sm"
      />
      <span
        class="error"
        *ngIf="Validate(data.form.get('location'), 'locationName')"
        >Origin must have Location Name</span
      >
    </div>
    <div class="form-group col-md-6 col-12">
      <span class="heading_4" for="address">Address*</span>
      <input
        type="address"
        [class.error-border]="Validate(data.form.get('location'), 'address')"
        [formControl]="data.form.get('location').get('address')"
        (ngModelChange)="
          SearchAddress(data.form.get('location').get('address').value)
        "
        id="address"
        class="form-control form-control-sm"
        [matAutocomplete]="address"
      />
      <mat-autocomplete #address="matAutocomplete">
        <mat-option
          [value]="address"
          (onSelectionChange)="
            UpdateAddress(data.form.get('location'), address)
          "
          *ngFor="let address of addressOptions"
        >
          <span>{{ address }}</span>
        </mat-option>
      </mat-autocomplete>
      <span class="error" *ngIf="Validate(data.form.get('location'), 'address')"
        >Origin must have Address
      </span>
    </div>
    <div class="form-group col-md-2 col-12">
      <span class="heading_4" for="contact_name">Contact Name</span>
      <input
        type="contactname"
        [class.error-border]="Validate(data.form, 'contactName')"
        [formControl]="data.form.get('contactName')"
        id="contact_name"
        class="form-control form-control-sm"
      />
    </div>
    <div class="form-group col-md-2 col-12">
      <span class="heading_4" for="contact_phone">Contact Phone</span>
      <input
        type="contactphone"
        [class.error-border]="Validate(data.form, 'contactPhone')"
        [formControl]="data.form.get('contactPhone')"
        id="contact_phone"
        class="form-control form-control-sm"
      />
    </div>
  </div>
  <div class="row">
    <div class="row flex-column form-group col-md-2 col-12">
      <span class="heading_4">Early Pick-Up Date*</span>
      <div class="input-group">
        <input
          type="text"
          [formControl]="data.form.get('earlyDate')"
          [matDatepicker]="pickerr"
          class="form-control form-control-sm"
        />
        <div
          class="input-group-append form-control-sm"
          (click)="pickerr.open()"
        >
          <ts-icon name="calendar"></ts-icon>
        </div>
        <mat-datepicker #pickerr></mat-datepicker>
      </div>
      <span class="error" *ngIf="Validate(data.form, 'earlyDate')"
        >Origin must have Early Pick-up Date</span
      >
    </div>

    <div class="row flex-column form-group col-md-2 col-12">
      <span class="heading_4">Early Pick-Up Time</span>
      <input
        type="time"
        id="earlypickuptime"
        [class.error-border]="Validate(data.form, 'earlyTime')"
        [formControl]="data.form.get('earlyTime')"
        class="form-control form-control-sm"
      />
      <span class="error" *ngIf="Validate(data.form, 'earlyTime')"
        >Origin must have Early Pickup Time</span
      >
    </div>
    <div class="form-group flex-column col-md-2 col-12">
      <span class="heading_4">Late Pick-Up Date*</span>
      <div class="input-group">
        <input
          type="text"
          [formControl]="data.form.get('lateDate')"
          [matDatepicker]="picker2"
          class="form-control form-control-sm"
        />
        <div
          class="input-group-append form-control-sm"
          (click)="picker2.open()"
        >
          <ts-icon name="calendar"></ts-icon>
        </div>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <span class="error" *ngIf="Validate(data.form, 'lateDate')"
        >Origin must have Late Pick-up Date</span
      >
    </div>
    <div class="form-group flex-column col-md-2 col-12">
      <span class="heading_4" for="time">Late Pick-Up Time</span>
      <input
        type="time"
        id="latepickuptime"
        [class.error-border]="Validate(data.form, 'lateTime')"
        [formControl]="data.form.get('lateTime')"
        class="form-control form-control-sm"
      />
      <span class="error" *ngIf="Validate(data.form, 'lateTime')"
        >Origin must have Late Pick-up Time</span
      >
    </div>
    <div class="form-group col-md-4 col-12">
      <div class="d-flex position-relative">
        <span for="contact_address">Stop Notes</span>
        <ts-tooltip
          class="pl-1 hydrated"
          options="{{ tooltipOptions.contactAddress }}"
        >
          <ts-icon class="loadpost-info-icon" name="information-circle">
          </ts-icon>
        </ts-tooltip>
      </div>
      <textarea
        class="form-control"
        [formControl]="data.form.get('stopNotes')"
      ></textarea>
    </div>
  </div>
  <div class="row no-gutters flex-column d-flex">
    <span class="heading_4" for="numbers">Reference Numbers</span>
    <div class="d-flex align-items-center">
      <input
        #reference
        type="numbers"
        id="reference numbers"
        class="form-control col-md-2 form-control-sm"
      />
      <ts-icon
        name="plus"
        class="p-2"
        (click)="addReference(data.form.get('referenceNumbers'), reference)"
      ></ts-icon>
      <mat-chip-list>
        <mat-chip
          *ngFor="
            let reference of data.form.get('referenceNumbers').value;
            let i = index
          "
          [removable]="true"
          (removed)="deleteReference(data.form.get('referenceNumbers'), i)"
        >
          {{ reference }}
          <ts-icon matChipRemove *ngIf="true" name="close"></ts-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <mat-divider></mat-divider>
</ng-template>
