<div class="login-main">
  <ngx-spinner
    color="#93272c"
    bdColor="rgba(0, 0, 0, 0.4)"
    type="ball-clip-rotate"
    size="medium"
  >
  </ngx-spinner>

  <div class="login-form">
    <img
      src="./assets/icons/svg/truckstop-prime-logo.svg"
      class="prime-logo"
      alt="Prime Logo"
    />
    <form [formGroup]="loginForm">
      <div class="fields-container">
        <mat-form-field class="login-fields">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            spellcheck="false"
            autocapitalize="off"
            formControlName="username"
            required
            data-cy="email"
          />
          <mat-error
            *ngIf="
              loginForm.controls.username.hasError('email') &&
              !loginForm.controls.username.hasError('required')
            "
            data-cy="invalid-email"
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field class="login-fields">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            spellcheck="false"
            autocapitalize="off"
            formControlName="password"
            required
            data-cy="password"
          />
          <mat-error
            *ngIf="loginForm.controls.password.hasError('required')"
            data-cy="password-req-error"
          >
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <button type="submit" mat-button (click)="doLogin()" class="login-btn">
          Login
        </button>
      </div>
    </form>
  </div>
</div>
