<div mat-dialog-content class="m-0" *ngIf="action == 'add'">
  <div class="row">
    <div class="col contact-title">
      Add New Contact
    </div>
    <div class="col-1">
      <i
        class="fa fa-times float-right"
        aria-hidden="true"
        (click)="onConfirm()"
      ></i>
    </div>
  </div>
  <hr class="m-0 p-0" />
  <form class="example-form mt-2" [formGroup]="addContactForm">
    <div class="form-group">
      <label>
        <mat-checkbox class="example-margin" formControlName="isPrimary"
          >Set As Primary Contact</mat-checkbox
        >
      </label>
    </div>
    <div class="form-group">
      <label>Contact Type</label>
      <input
        type="text"
        formControlName="contactTypeName"
        class="form-control height-form"
      />
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <label>First Name</label>
        <input
          type="text"
          formControlName="firstName"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-6">
        <label>Last Name</label>
        <input
          type="text"
          formControlName="lastName"
          class="form-control height-form"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-4">
        <label>Phone</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-2">
        <label>Ext</label>
        <input
          type="text"
          formControlName="ext"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-6">
        <label>Mobile Phone (Optional)</label>
        <input
          type="text"
          formControlName="mobile"
          class="form-control height-form"
        />
      </div>
    </div>
    <div class="form-group">
      <label>Email Address</label>
      <input
        type="text"
        formControlName="email"
        class="form-control height-form"
      />
    </div>
    <div mat-dialog-actions class="row justify-content-center my-2 mt-3">
      <button mat-button class="cancelbtn mr-4" (click)="onConfirm()">
        Cancel
      </button>
      <button
        mat-button
        cdkFocusInitial
        class="submitbtn ml-3"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </form>
</div>

<div mat-dialog-content class="m-0" *ngIf="action == 'edit'">
  <div class="row">
    <div class="col contact-title">
      Edit contact
    </div>
    <div class="col-1">
      <i
        class="fa fa-times float-right"
        aria-hidden="true"
        (click)="onConfirm()"
      ></i>
    </div>
  </div>
  <hr class="m-0 p-0" />
  <form class="example-form mt-2" [formGroup]="editContactForm">
    <div class="form-group">
      <label>
        <mat-checkbox class="example-margin" formControlName="isPrimary"
          >Set As Primary Contact</mat-checkbox
        >
      </label>
    </div>
    <div class="form-group">
      <label>Contact Type</label>
      <input
        type="text"
        formControlName="contactTypeName"
        class="form-control height-form"
      />
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <label>First Name</label>
        <input
          type="text"
          formControlName="firstName"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-6">
        <label>Last Name</label>
        <input
          type="text"
          formControlName="lastName"
          class="form-control height-form"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-4">
        <label>Phone</label>
        <input
          type="text"
          formControlName="phone"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-2">
        <label>Ext</label>
        <input
          type="text"
          formControlName="ext"
          class="form-control height-form"
        />
      </div>
      <div class="form-group col-6">
        <label>Mobile Phone (Optional)</label>
        <input
          type="text"
          formControlName="mobile"
          class="form-control height-form"
        />
      </div>
    </div>
    <div class="form-group">
      <label>Email Address</label>
      <input
        type="text"
        formControlName="email"
        class="form-control height-form"
      />
    </div>
    <div mat-dialog-actions class="row justify-content-center my-2 mt-3">
      <button
        mat-button
        class="cancelbtn mr-4"
        (click)="onConfirm()"
        matDialogClose
      >
        Cancel
      </button>
      <button
        mat-button
        cdkFocusInitial
        class="submitbtn ml-3"
        (click)="onUpdate()"
      >
        Update
      </button>
    </div>
  </form>
</div>
