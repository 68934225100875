import { Injectable } from '@angular/core';
import { CoreModule } from './core.module';

@Injectable({
  providedIn: CoreModule,
})
export class PubSub {
  static subscriptions: Map<string, any[]> = new Map<string, any[]>();

  publish(eventName: string, data: any) {
    if (PubSub.subscriptions.has(eventName)) {
      PubSub.subscriptions.get(eventName).forEach((callback) => callback(data));
    }
  }

  subscribe(eventName: string, callback: any) {
    if (!PubSub.subscriptions.has(eventName)) {
      PubSub.subscriptions.set(eventName, []);
    }
    PubSub.subscriptions.get(eventName).push(callback);
  }

  unsubscribe(eventName: string, callback: any) {
    if (PubSub.subscriptions.has(eventName)) {
      const index = PubSub.subscriptions.get(eventName).indexOf(callback);
      if (index >= 0) {
        PubSub.subscriptions.get(eventName).splice(index, 1);
      }
    }
  }
}
