import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConstants } from '../constants/auth.constant';
import { JwtHelper } from './jwthelper.service';
import { PubSub } from './pubsub.service';
import { IClaims } from './core.models';
import { CoreModule } from './core.module';

@Injectable({
  providedIn: CoreModule,
})
export class AuthService {
  _eventPublisher: PubSub;

  constructor(private _router: Router, private _jwtHelper: JwtHelper) {
    this._eventPublisher = new PubSub();
  }

  isSignedIn(): boolean {
    return !this.isTokenExpired(this.getToken());
  }

  getClaims(): IClaims {
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo && tokenInfo.claims) {
      return tokenInfo.claims as IClaims;
    }
  }

  hasProductAccess(productName: string): boolean {
    const claims = this.getClaims();
    if (claims) {
      return claims[productName] === 'true';
    }
    return false;
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['/login'], {
      queryParams: null,
    });
  }
  getUserName(): string {
    return this.getClaims().userName;
  }

  getToken() {
    return localStorage.getItem(AuthConstants.TOKEN);
  }

  setToken(token: string) {
    localStorage.setItem(AuthConstants.TOKEN, token);
    this.onTokenChange(token);
  }

  clearToken() {
    localStorage.removeItem(AuthConstants.TOKEN);
    this.onTokenChange(undefined);
  }

  getTokenInfo() {
    return this._jwtHelper.getJwtHelper().decodeToken(this.getToken());
  }

  getUserInfo() {
    const _decodedToken = this.getTokenInfo();
    return {
      userId: _decodedToken.userId,
      accountType: _decodedToken.accountType,
    };
  }

  isTokenExpired(token: string) {
    return this._jwtHelper.getJwtHelper().isTokenExpired(token);
  }

  onTokenChange(token: string) {
    this._eventPublisher.publish('tokenChange', token);
  }
}
