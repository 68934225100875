<nav mat-tab-nav-bar class="load_navbar" [disableRipple]="true">
  <a
    class="load_navlink"
    mat-tab-link
    routerLink="/app/loads/tab-view/newload"
    [routerLinkActive]="'load_navlink_active'"
    [active]="router.url === '/app/loads/tab-view/newload'"
    >NEW LOAD</a
  >
  <a
    class="load_navlink"
    mat-tab-link
    routerLink="/app/loads/tab-view/favorites"
    [routerLinkActive]="'load_navlink_active'"
    [active]="router.url === '/app/loads/tab-view/favorites'"
    >FAVORITES</a
  >
  <a
    class="load_navlink"
    mat-tab-link
    routerLink="/app/loads/tab-view/upload"
    [routerLinkActive]="'load_navlink_active'"
    [active]="router.url === '/app/loads/tab-view/upload'"
    >UPLOAD</a
  >
</nav>
<router-outlet></router-outlet>
