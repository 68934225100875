import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './../../components/confirm-dialog/confirm-dialog.component';
import { CustomersService } from '../../core/customer.service';
import { AddContactDialogComponent } from './../../components/add-contact-dialog/add-contact-dialog.component';
import { ShipperContacts } from '../../models/customers.model';
// export interface DialogData {
//   id: any;
// }
@Component({
  selector: 'webapp-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerDetailsComponent implements OnInit {
  bussinessFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  billingFormGroup: FormGroup;
  result = '';
  activeTab = 2;
  tabIndex = {
    BUSINESS: 0,
    ADDRESS: 1,
    CONTACTS: 2,
    BILLING: 3,
  };
  payments: any = [
    { modeId: 1, name: 'ACH' },
    { modeId: 2, name: 'CHECK' },
    { modeId: 3, name: 'Wire' },
    { modeId: 4, name: 'Credit Card' },
  ];
  filteredIndustry: [];
  states: [];
  country: string[] = ['USA', 'CAN'];
  options = [{ name: 'USA' }, { name: 'CAN' }];

  url;
  filteredOptions: Observable<any[]>;
  id: any;
  shipperContacts: any;
  shipperContactId: string;
  shipperData: any;
  newContact: any;
  deletedContact: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _customerService: CustomersService
  ) {}
  ngOnInit(): void {
    this.bussinessFormGroup = this.formBuilder.group({
      companyName: ['', [Validators.required]],
      businessType: [''],
      industry: [],
      companyId: [''],
      countryOfIncorporation: [''],
      stateOfIncorporation: [''],
      website: [''],
    });
    this.addressFormGroup = this.formBuilder.group({
      mainZipCode: [],
      mainAddress1: [],
      mainCity: [],
      mainCountryCode: [],
      mainAddress2: [],
      mainStateCode: [],
      billingZipCode: [],
      billingAddress1: [],
      billingCity: [],
      billingCountryCode: [],
      billingAddress2: [],
      billingStateCode: [],
    });
    this.billingFormGroup = this.formBuilder.group({
      isOriginalBillRequired: [false],
      email: [],
      usMail: [],
      billPaymentModeId: [],
    });
    this.route.queryParams.subscribe((params) => {
      this.activeTab = +params['tab'] || 0;
      if (this.activeTab == 2) {
        this.id = params.id;
        this.shipperContacts = params.contacts
          ? JSON.parse(params.contacts)
          : params.contacts;
      } else {
        this.shipperData = params.shipperData
          ? JSON.parse(params.shipperData)
          : params.shipperData;
        this._setFormValue();
        console.log(this.shipperData);
        this.id = this.shipperData.id;
        this.shipperContacts = this.shipperData.shipperContacts;
      }
    });
  }

  displayFn(user): string {
    return user && user.definition ? user.definition : '';
  }

  confirmDialog(shipperContactDelete): void {
    // const message = `Delete this contact?`;

    // const dialogData = new ConfirmDialogModel(
    //   '',
    //   message,
    //   'This action cannot be undone.'
    // );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure want to delete this contact?',
        buttonText: {
          ok: 'Delete',
          cancel: 'Cancel',
        },
        shipperId: this.id,
        shipperContactId: shipperContactDelete.id,
        data: shipperContactDelete,
      },
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe((result: ShipperContacts) => {
      this.deletedContact = result;
      console.log(this.deletedContact);
      for (var i = 0; i < this.shipperContacts.length; i++) {
        if (this.shipperContacts[i].id == this.deletedContact.id)
          this.shipperContacts.splice(i);
      }
    });
  }

  addContact() {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      data: { id: this.id, action: 'add' },
      width: '550px',
    });
    dialogRef.afterClosed().subscribe((result: ShipperContacts) => {
      //this.newContact = result;
      //this.shipperContacts.push(this.newContact);
    });
  }

  editContact(updateContact) {
    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      data: { id: this.id, updateContact: updateContact, action: 'edit' },
      width: '550px',
    });
    dialogRef.afterClosed().subscribe((result: ShipperContacts) => {
      this.newContact = result;
      //this.shipperContacts.push(this.newContact);
    });
  }
  onBackButton() {
    this.router.navigate(['/app/customer']);
  }

  checkBillingAddress(event) {
    if (event) {
      this.addressFormGroup
        .get('billingAddress1')
        .patchValue(this.addressFormGroup.value.mainAddress1);
      this.addressFormGroup
        .get('billingAddress2')
        .patchValue(this.addressFormGroup.value.mainAddress2);
      this.addressFormGroup
        .get('billingCity')
        .patchValue(this.addressFormGroup.value.mainCity);
      this.addressFormGroup
        .get('billingCountryCode')
        .patchValue(this.addressFormGroup.value.mainCountryCode);
      this.addressFormGroup
        .get('billingZipCode')
        .patchValue(this.addressFormGroup.value.mainZipCode);
      this.addressFormGroup
        .get('billingStateCode')
        .patchValue(this.addressFormGroup.value.mainStateCode);

      // this.addressFormGroup.value.billingAddress1 = this.addressFormGroup.value.mainAddress1;
      // this.addressFormGroup.value.billingAddress2 = this.addressFormGroup.value.mainAddress2;
      // this.addressFormGroup.value.billingCity = this.addressFormGroup.value.mainCity;
      // this.addressFormGroup.value.billingCountryCode = this.addressFormGroup.value.mainCountryCode;
      // this.addressFormGroup.value.billingZipCode = this.addressFormGroup.value.mainZipCode;
      // this.addressFormGroup.value.billingStateCode = this.addressFormGroup.value.mainStateCode;
    } else {
      this.addressFormGroup.get('billingAddress1').patchValue('');
      this.addressFormGroup.get('billingAddress2').patchValue('');
      this.addressFormGroup.get('billingCity').patchValue('');
      this.addressFormGroup.get('billingCountryCode').patchValue('');
      this.addressFormGroup.get('billingZipCode').patchValue('');
      this.addressFormGroup.get('billingStateCode').patchValue('');
    }
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }
  doIndustrySearch(event) {
    const val = event.target.value;
    this._getIndustrySuggestion(val);
  }
  updateShipper(formdata) {
    this.mapRequestBody(
      this.bussinessFormGroup.value,
      this.addressFormGroup.value,
      this.billingFormGroup.value
    );
    this._updateShipper(this.shipperData.id, this.shipperData);
  }
  mapRequestBody(bussiness, address, billing) {
    this.shipperData.name = bussiness.companyName;
    this.shipperData.businessType = bussiness.businessType;
    this.shipperData.siCcodeId =
      bussiness && bussiness.industry && bussiness.industry.id
        ? bussiness.industry.id
        : 1;
    this.shipperData.ssn = bussiness.companyId;
    this.shipperData.daysToPay = 2;
    this.shipperData.countryOfIncorporation = bussiness.countryOfIncorporation;
    this.shipperData.stateOfIncorporation = bussiness.stateOfIncorporation;
    this.shipperData.website = bussiness.website;
    this.shipperData.mainAddress1 = address.mainAddress1;
    this.shipperData.mainAddress2 = address.mainAddress2;
    this.shipperData.mainCity = address.mainCity;
    this.shipperData.mainStateCode = address.mainStateCode;
    this.shipperData.mainZipCode = address.mainZipCode;
    this.shipperData.billingAddress1 = address.billingAddress1;
    this.shipperData.billingAddress2 = address.billingAddress2;
    this.shipperData.billingCity = address.billingCity;
    this.shipperData.billingStateCode = address.billingStateCode
      ? address.billingStateCode
      : null;
    this.shipperData.billingZipCode = address.billingZipCode;
    this.shipperData.billingEmail = billing.email;
    this.shipperData.billingUSMail = billing.usMail;
    this.shipperData.isOriginalBillRequired = billing.isOriginalBillRequired;
    this.shipperData.billPaymentMethodId =
      billing && billing.billPaymentModeId ? billing.billPaymentModeId : 1;
  }
  private _updateShipper(id: string, data) {
    this._customerService.updateShipper(id, data).subscribe(
      (res) => {
        console.log(res);
      },
      (e) => {
        console.log(e);
      }
    );
  }
  private _getIndustrySuggestion(val: string) {
    if (val && val.trim() !== '' && val.length > 1) {
      this._customerService.getIndustrySuggestions(val).subscribe(
        (data) => {
          this.filteredIndustry = data ? data : [];
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }
  private _setFormValue() {
    if (this.shipperData) {
      this.bussinessFormGroup
        .get('companyName')
        .patchValue(this.shipperData.name);
      this.bussinessFormGroup
        .get('businessType')
        .patchValue(this.shipperData.businessType);
      // this.bussinessFormGroup.get('industry').patchValue(this.shipperData.siCcodeId);
      this.bussinessFormGroup.get('companyId').patchValue(this.shipperData.ssn);
      this.bussinessFormGroup
        .get('countryOfIncorporation')
        .patchValue(this.shipperData.countryOfIncorporation);
      this.bussinessFormGroup
        .get('stateOfIncorporation')
        .patchValue(this.shipperData.stateOfIncorporation);
      this.bussinessFormGroup
        .get('website')
        .patchValue(this.shipperData.website);
      this.addressFormGroup
        .get('mainZipCode')
        .patchValue(this.shipperData.mainZipCode);
      this.addressFormGroup
        .get('mainAddress1')
        .patchValue(this.shipperData.mainAddress1);
      this.addressFormGroup
        .get('mainCountryCode')
        .patchValue(this.shipperData.mainCountryCode);

      this.addressFormGroup
        .get('mainAddress2')
        .patchValue(this.shipperData.mainAddress2);
      this.addressFormGroup
        .get('mainStateCode')
        .patchValue(this.shipperData.mainStateCode);
      this.addressFormGroup
        .get('billingZipCode')
        .patchValue(this.shipperData.billingZipCode);
      this.addressFormGroup
        .get('billingAddress1')
        .patchValue(this.shipperData.billingAddress1);
      this.addressFormGroup
        .get('billingCountryCode')
        .patchValue(this.shipperData.billingCountryCode);
      this.addressFormGroup
        .get('billingAddress2')
        .patchValue(this.shipperData.billingAddress2);
      this.addressFormGroup
        .get('billingStateCode')
        .patchValue(this.shipperData.billingStateCode);
      this.billingFormGroup
        .get('isOriginalBillRequired')
        .patchValue(this.shipperData.isOriginalBillRequired);
      this.billingFormGroup
        .get('email')
        .patchValue(this.shipperData.billingEmail);
      this.billingFormGroup
        .get('usMail')
        .patchValue(this.shipperData.billingUSMail);
      this.billingFormGroup
        .get('billPaymentModeId')
        .patchValue(this.shipperData.billPaymentMethodId);
    }
  }
}
