<div class="load p-4">
  <div class="d-flex align-items-center">
    <div class="load_title flex-grow-1">Load Management</div>
    <button
      *ngIf="
        router.url === '/app/loads' || router.url === '/app/loads/action-view'
      "
      [routerLink]="'/app/loads/tab-view/newload'"
      mat-button
      class="load_btn"
    >
      New Load
    </button>
  </div>
  <div class="mt-4">
    <router-outlet></router-outlet>
  </div>
</div>
