import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../core/auth.service';
import { LoginService } from '../../core/login.service';
import { LOGIN_ERRORS } from './login.constants';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  error: string;

  loginSubscription: Subscription;

  loginForm = this._formBuilder.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _loginService: LoginService,
    private _router: Router,
    private _spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  doLogin() {
    const { username, password } = this.loginForm.value;
    this._spinner.show();
    this.loginSubscription = this._loginService
      .login(username, password, 'truckstop')
      .subscribe(
        (res) => {
          this._getUser();
          console.log(res);
          this._spinner.hide();
          if (this._authService.hasProductAccess('isProUser')) {
            this._router.navigate(['/app']);
          } else {
            this.error = LOGIN_ERRORS.NON_PRO;
          }
        },
        () => {
          this._spinner.hide();
          this.error = LOGIN_ERRORS.INVALID;
        }
      );
  }
  private _getUser() {
    this._loginService.getUser().subscribe(
      (res) => {
        localStorage.setItem('UserID', res.id);
        localStorage.setItem('accountId', res.accountId);
      },
      () => {}
    );
  }
}
