import {
  Component,
  OnInit,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
  FormControlName,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogComponent } from '../../../components/dialog/dialog/dialog.component';
import { LoadConstants } from './newload.constants';
import { LoadApiService } from './newload.service';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
  EquipmentOptionsLookup,
  ModeLookup,
  EquipmentLookup,
  PostAsLookup,
  TermsLookup,
  LookupService,
  CommodityLookup,
} from '../lookup-service';
import {
  StopType,
  LoadStop,
  Load,
  LoadRequestV2Contract,
  LocationAddress,
} from './newload.model';
import { NewLoadFormHelper } from './newload.forms';

@Component({
  selector: 'prime-newload',
  templateUrl: './newload.component.html',
  styleUrls: ['./newload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewloadComponent implements OnInit {
  load: FormGroup;
  loadForm: FormGroup;
  loadFormHelper: NewLoadFormHelper;
  customers: string[];
  equipmentOptions: EquipmentOptionsLookup[] = [];
  modes: ModeLookup[] = [];
  equipmentTypes: EquipmentLookup[] = [];
  postAs: PostAsLookup[] = [];
  terms: TermsLookup[] = [];
  public commodity: CommodityLookup[] = [];
  public rateChartData: {
    data: any;
    error: string;
  };
  public addressOptions: string[];
  public get Equipment() {
    return this.loadForm.get('equipmentAttributes');
  }
  public get RateAttributes() {
    return this.loadForm.get('rateAttributes');
  }
  public get Dimensions() {
    return this.loadForm.get('dimensional');
  }
  public get TermsAndConditions() {
    return this.loadForm.get('termsAndConditions');
  }
  public get origination() {
    const stops = this.loadForm.get('loadStops') as FormArray;
    let origin = stops.controls.find((x) => {
      const stopType = x.get('type').value as StopType;
      const required = x.get('required').value as boolean;
      return stopType === StopType.Origin && required;
    });
    if (origin == null) {
      origin = this.loadFormHelper.createLoadStopForm({
        type: StopType.Origin,
        required: true,
      } as LoadStop);
      stops.controls.push(origin);
    }
    return origin;
  }
  public get destination() {
    const stops = this.loadForm.get('loadStops') as FormArray;
    let destination = stops.controls.find((x) => {
      const stopType = x.get('type').value as StopType;
      const required = x.get('required').value as boolean;
      return stopType === StopType.Destination && required;
    });
    if (destination == null) {
      destination = this.loadFormHelper.createLoadStopForm({
        type: StopType.Destination,
        required: true,
      } as LoadStop);
      stops.controls.push(destination);
    }
    return destination;
  }
  public get additionalStops() {
    const stops = this.loadForm.get('loadStops') as FormArray;
    const destinationStops = stops.controls.filter((x) => {
      const stopType = x.get('type').value as StopType;
      const required = x.get('required').value as boolean;
      return stopType === StopType.Destination && !required;
    });
    return destinationStops;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private loadService: LoadApiService,
    private lookupService: LookupService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadFormHelper = new NewLoadFormHelper(this.fb, this.lookupService);
    const form = {
      loadRequestV2Contract: {
        dimensional: {},
        loadActionAttributes: {},
        equipmentAttributes: {},
        loadStops: [],
        termsAndConditions: {},
        rateAttributes: { tenderAllInRate: {}, postedAllInRate: {} },
      },
    } as Load;
    this.load = this.loadFormHelper.createLoadForm(form);
    this.loadForm = this.load.get('loadRequestV2Contract') as FormGroup;
    this.lookupService
      .LookupEquipmentOptions()
      .subscribe((res) => (this.equipmentOptions = res));
    this.lookupService
      .LookupEquipmentTypes()
      .subscribe((res) => (this.equipmentTypes = res));
    this.lookupService.LookupMode().subscribe((res) => (this.modes = res));
    this.lookupService.LookupTerms().subscribe((res) => (this.terms = res));
    this.lookupService.LookupPostAs().subscribe((res) => (this.postAs = res));
    this.loadService.Customers().subscribe((res) => (this.customers = res));
    this.lookupService
      .LookupCommodity()
      .subscribe((res) => (this.commodity = res));
    this.rateChartData = {
      error: '',
      data: LoadConstants.rateChartRender({
        posted90DayRate: 0,
        posted15DayRate: 0,
        posted30DayRate: 0,
        posted7DayRate: 0,
      }),
    };
  }
  SearchAddress(val) {
    this.lookupService
      .LookupAddress(val)
      .subscribe((options) => (this.addressOptions = options));
  }
  addAddtionalStop() {
    const detail = this.loadFormHelper.createLoadStopForm({
      type: StopType.Destination,
      required: false,
    } as LoadStop);
    const stops = this.loadForm.get('loadStops') as FormArray;
    stops.controls.push(detail);
  }
  deleteAddtionalStop(stop: FormGroup) {
    const dialogRef = this.dialog.open(DialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const stops = this.loadForm.get('loadStops') as FormArray;
        const i = stops.controls.findIndex((x) => x === stop);
        stops.controls.splice(i, 1);
      }
    });
  }
  addReference(references: FormControl, reference) {
    const refs = (references.value as Array<string>) || [];
    if (reference.value) {
      refs.push(reference.value);
      references.setValue(refs);
    }
    reference.value = '';
  }
  deleteReference(references: FormControl, index: number) {
    const refs = (references.value as Array<string>) || [];
    refs.splice(index, 1);
    references.setValue(refs);
  }
  UpdateAddress(locationForm: FormGroup, value) {
    this.lookupService.LookupLocation(value).subscribe((res) => {
      locationForm.get('streetAddress1').setValue(res.address);
      locationForm.get('streetAddress2').setValue(res.address2);
      locationForm.get('city').setValue(res.city);
      locationForm.get('countryCode').setValue(res.countryCode);
      locationForm.get('state').setValue(res.stateCode);
    });
  }

  Validate(form: FormGroup, name: string) {
    if (!form.get(name)) {
      console.log(name);
    }

    return (
      (form.get(name).dirty || form.get(name).touched) &&
      form.get(name).invalid &&
      form.get(name).errors.required
    );
  }
  public LookupRate() {
    // const origin = this.loadForm.get('Origin').get('Address');
    const equipment = this.equipmentTypes.find(
      (x) => x.id === this.Equipment.get('equipmentTypeId').value
    );
    const destination = this.destination.get('location');
    const origination = this.origination.get('location');
    const dto = {
      DCity: destination.get('city').value,
      DCountry: destination.get('countryCode').value,
      DState: destination.get('state').value,
      Equipment: equipment ? equipment.code : '',
      OCity: origination.get('city').value,
      OCountry: origination.get('countryCode').value,
      OState: origination.get('state').value,
    };

    this.lookupService.LookupLoad(dto).subscribe((res) => {
      if (!isNaN(res.posted15DayRate) && !isNaN(res.posted7DayRate)) {
        this.rateChartData = {
          data: LoadConstants.rateChartRender(res),
          error: '',
        };
      } else {
        this.rateChartData = {
          error: 'Insufficient data for this lane',
          data: LoadConstants.rateChartRender({
            posted90DayRate: 0,
            posted15DayRate: 0,
            posted30DayRate: 0,
            posted7DayRate: 0,
          }),
        };
      }
    });
  }
  SaveDraft() {
    // this.markFormGroupTouched(this.load);
    // if(this.load.invalid){
    //   return;
    // }
    this.loadService.SaveDraft(this.load.getRawValue()).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/app/loads']);
    });
  }

  Source() {
    // this.markFormGroupTouched(this.load);
    // if(this.load.invalid){
    //   return;
    // }
    this.loadService.Source(this.load.getRawValue()).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/app/loads']);
    });
  }
  onCustomerSelect({ value }) {
    const shipperID = this.load.get('shipperId');
    shipperID.setValue(value.id);
    const shippercontactId =
      value.shipperContacts.length > 0 ? value.shipperContacts[0].id : null;
    const shipperContactID = this.load.get('shipperContactId');
    shipperContactID.setValue(shippercontactId);
    // shipperContactId
    // shipperid -
    // value
    console.log(value);
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  public get progressChart() {
    return LoadConstants.progressChart;
  }
  public get tooltipOptions() {
    return LoadConstants.tooltipOptions;
  }
}
