import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Load } from './newload.model';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../core/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoadApiService {
  private base_url = environment.PRIME_URL;
  private load_url = `${this.base_url}/truckstopprime/v1`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  public Customers() {
    const dto = {
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
    return this.http
      .post(this.load_url + '/shipper/search', JSON.stringify(dto), {
        headers: this.headers(),
      })
      .pipe(
        map((res: any) => {
          const data = res.data.map((x) => x);
          return data;
        })
      );
  }
  public SaveLoadToDraft(dto: Load) {
    const body = JSON.stringify(dto);
    return this.http.post(this.load_url + '/load', body, {
      headers: this.headers(),
    });
  }

  public SaveDraft(load: Load) {
    // load.shipperId = 'eb722582-ef05-40f9-b0f0-38cc36633838';
    // load.shipperContactId = '3cabc82b-8b47-46ab-8522-602c0890938c';
    return this.http.post(this.load_url + '/load', JSON.stringify(load), {
      headers: this.headers(),
    });
  }

  public Source(load: Load) {
    // load.shipperId = 'eb722582-ef05-40f9-b0f0-38cc36633838';
    // load.shipperContactId = '3cabc82b-8b47-46ab-8522-602c0890938c';
    return this.http.post(this.load_url + '/binload', JSON.stringify(load), {
      headers: this.headers(),
    });
  }

  private headers() {
    const headers = {
      Authorization: `Bearer ${this.authService.getTokenInfo().accessToken}`,
      'Content-Type': 'application/json',
    };
    return headers;
  }
}
