import { RateLookup } from '../lookup-service';

export const LoadConstants = {
  progressChart: {
    config: JSON.stringify({
      margins: [80, 10, 80, 10],
      background: '#ffffff',
      xAxisHide: true,
      yAxisHide: true,
    }),
    data: JSON.stringify({
      type: 'progress',
      style: {
        label: {
          color: '#373a36',
          size: 10,
        },
        bar: {
          color: '#b11721,#d85e1b',
          cornerRadius: 8,
        },
        backdrop: {
          color: '#c9c9c9',
          borderColor: '#afb0af',
          borderWeight: 1,
          cornerRadius: 8,
        },
      },
      series: [
        {
          showLabels: true,
          labelFormat: '.0%',
          labelPrefix: 'Post Strength ',
          labelPosition: 'middle',
          showNames: false,
          values: [
            {
              value: 0.35,
            },
          ],
        },
      ],
    }),
  },
  rateChart: {
    data: JSON.stringify({
      posted7DayRate: 2.15,
      posted15DayRate: 2.03,
      posted30DayRate: 2.12,
      posted90DayRate: 2.05,
    }),
  },
  rateChartRender: (res: RateLookup) => {
    const chart6 = {
      name: 'Chart 6',
      description: 'Line chart with custom node labels',
      config: {
        margins: [30, 30, 50, 40],
        background: '#373a36',
        xAxis: {
          line: {
            color: '#ffffff',
            weight: 1,
          },
          label: {
            color: '#D073BA',
            size: 10,
          },
          name: {
            color: '#ffffff',
            size: 12,
          },
        },
        yAxis: {
          line: {
            color: '#ffffff',
            weight: 1,
          },
          label: {
            color: '#D073BA',
            size: 10,
          },
          name: {
            color: '#ffffff',
            size: 12,
          },
        },
      },
      data: {
        type: 'line',
        style: {
          line: {
            color: '#D073BA',
            weight: 2,
          },
          node: {
            borderColor: '#11141C',
            borderWeight: 2,
            color: '#D073BA',
            size: 5,
          },
        },
        xAxis: {
          name: 'Days',
          namePadding: -5,
          values: [1, 2, 3, 4],
          labels: ['7D', '15D', '30D', '90D'],
          minValue: 0.5,
          maxValue: 4.5,
          showLabels: false,
        },
        yAxis: {
          name: 'RPM',
          namePadding: 15,
          maxValue: 2.3,
          minValue: 1.9,
          traceLines: true,
          labelFormat: '.2f',
          showLabels: false,
          values: [
            {
              values: [
                res.posted7DayRate,
                res.posted15DayRate,
                res.posted30DayRate,
                res.posted90DayRate,
              ],
              labelFormat: '$.2f',
              showLabels: true,
              style: {
                label: {
                  color: '#ffffff',
                  size: 10,
                },
                line: {
                  color: '#ffffff',
                  weight: 2,
                },
                node: {
                  borderColor: '#12a3e6',
                  borderWeight: 2,
                  color: '#ffffff',
                },
                traceLine: {
                  color: '#ffffff',
                  weight: 1,
                  dashes: '2 2',
                },
              },
            },
          ],
        },
      },
    };
  },
  tooltipOptions: {
    help: JSON.stringify({
      title: 'Click to learn more about the post strength bar',
      position: 'bottom',
    }),
    contactAddress: JSON.stringify({
      title:
        'Stop Notes are only visible to the Carrier on the Rate Confirmation once the Book It Now load is Booked.',
      placement: 'top-start',
    }),
    specialInformation: JSON.stringify({
      title:
        'This section is used to supply the carrier with information that is not represented by the fields herein but is imperative for the delivery of the load.',
      placement: 'top-end',
    }),
    rateConfirmationNotes: JSON.stringify({
      title:
        'Rate Confirmation Notes are only visible to the Carrier on the Rate Confirmation once the Book It Now load is Booked.',
      placement: 'top-start',
    }),
    BookItNow: JSON.stringify({
      title:
        'Assigning "Book It Now Carrier Groups" will ensure that only enabled carriers within those groups can Book It Now on this load. To use all of your enabled Preferred Carriers, do not select a Book It Now Carrier Group.',
      placement: 'top-start',
    }),
  },
};
