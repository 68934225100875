import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { LoadStatus } from './list-view.model';
import { debounceTime } from 'rxjs/operators';
import { ListViewService } from './list-view-service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListViewComponent implements OnInit {
  optionControl = new FormControl('', Validators.required);
  Options = [{ name: 'Pickup Date' }, { name: 'Latest' }];
  public listForm: FormGroup;
  public ShowFilters = false;
  public loadStatus: LoadStatus;
  public status = LoadStatus;
  public initialData = [];
  public loadData = [];

  columnDefs = [
    { headerName: 'Status', field: 'status', sortable: true },
    { headerName: 'Customer', field: 'customer', width: 300, sortable: true },
    { headerName: 'Origin', field: 'origin', width: 300, sortable: true },
    {
      headerName: 'Destination',
      field: 'destination',
      width: 300,
      sortable: true,
    },
    { headerName: 'Pickup', field: 'pickupdate', width: 300, sortable: true },
    { headerName: 'Stops', field: 'stops', sortable: true },
    { headerName: 'EQP', field: 'equipment', sortable: true },
    { headerName: 'Load #', field: 'load', sortable: true },
    { headerName: 'Label', field: 'label', sortable: true },
    { headerName: 'Rate', field: 'rate', sortable: true },
    { headerName: 'Actions', field: 'actions', sortable: true },
  ];
  constructor(private fb: FormBuilder, private listService: ListViewService) {}
  ngOnInit() {
    this.listService.GetLoads().subscribe((res) => {
      this.loadData = res.filter((x) => x);
      this.initialData = res.filter((x) => x);
    });
    this.listForm = this.fb.group({
      customer: new FormControl(''),
      PickupDate: new FormControl(''),
    });

    Object.keys(this.listForm.controls).map((control) => {
      this.listForm
        .get(control)
        .valueChanges.pipe(debounceTime(100))
        .subscribe((value) => this.FilterRows(control, value));
    });
  }
  FilterRows(property: string, value: string) {
    this.loadData = this.initialData.filter(
      (x) => !value || x[property].toLowerCase().includes(value.toLowerCase())
    );
  }
  onStatusChange(status: LoadStatus) {
    this.loadStatus = status;
  }
  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }
}

interface Filters {
  Customer: string;
}
