<div class="load p-2">
  <div class="flex-column">
    <div class="p-2">
      <mat-table class="row p-4" [dataSource]="dataSource">
        <ng-container matColumnDef="customer">
          <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.customer }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="pickup">
          <mat-header-cell *matHeaderCellDef> Pick-up </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.pickup }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delivery">
          <mat-header-cell *matHeaderCellDef> Delivery </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.delivery }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="equipment">
          <mat-header-cell *matHeaderCellDef> Equipment </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.equipment }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="d-flex justify-content-around align-items-center">
              <ts-icon class="p-2" name="pencil"></ts-icon>
              <ts-icon class="p-2" name="trash"></ts-icon>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <!-- <mat-divider></mat-divider> -->
    <div
      class="d-flex align-items-center justify-content-around row col-12 col md-6 p-2"
    >
      <ts-button
        outlined
        class="secondary-with-color hover-focus-indicator-color"
      >
        EXIT LOAD POST</ts-button
      >
    </div>
  </div>
</div>
