import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomersService } from '../../core/customer.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';
import { ShipperSearch } from '../../models/customers.model';
import { FormGroup, FormControl } from '@angular/forms';
export interface Section {
  icon: string;
  name: string;
  address: string;
}
@Component({
  selector: 'prime-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerComponent implements OnInit {
  shippersData: any;
  constructor(
    private _customerService: CustomersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  public totalCustomer: number;
  public daysToPayGreater30: number;
  public daysToPayLess30: number;
  public shipperList: any;
  public profileLogo: any;
  public openLoadsChart: any;
  public coveredLoadsChart: any;
  public intransitLoadsChart: any;
  public loadBarChart: any;
  public marginBarChart: any;
  public shipperContact: any;
  public totalRecords = 0;
  public itemsPerPage = 10;
  private _currentPage = 1;

  public searchData: ShipperSearch = <ShipperSearch>{};
  public selectedTab = 0;
  searchForm = new FormGroup({
    searchTerm: new FormControl(''),
  });
  ngOnInit(): void {
    this._getShipperCounts();
    this._getShipperSearch(
      this.searchForm.value.searchTerm,
      '',
      this._currentPage,
      this.itemsPerPage
    );
    this.openLoadsChart = {
      config: {
        margins: [2, 118, 2, 2],
        background: '#fff',
        displayLegend: true,
        legendStyle: {
          position: 4, // TsChartLegendPosition.MID_LEFT
          orientation: 0, // TsChartLegendOrientation.VERTICAL,
          fontSize: 10,
          width: 118,
        },
      },
      data: {
        type: 'donut',
        style: {
          donut: {
            borderColor: '#ffffff',
            borderWeight: 1,
            thickness: 40,
            hoverEffect: false,
          },
        },
        series: {
          showLabels: false,
          values: [
            {
              name: 'Revoked',
              value: 3,
              color: '#E11B22',
            },
            {
              name: 'Expired',
              value: 10,
              color: '#6A6A6A',
            },
            {
              name: 'Dropped',
              value: 2,
              color: '#676767',
            },
            {
              name: 'Sourcing',
              value: 22,
              color: '#5E8AB4',
            },
          ],
        },
      },
    };
    this.coveredLoadsChart = {
      config: {
        margins: [2, 118, 2, 2],
        background: '#fff',
        displayLegend: true,
        legendStyle: {
          position: 4, // TsChartLegendPosition.MID_LEFT
          orientation: 0, // TsChartLegendOrientation.VERTICAL,
          fontSize: 10,
          width: 118,
        },
      },
      data: {
        type: 'donut',
        style: {
          donut: {
            borderColor: '#ffffff',
            borderWeight: 1,
            thickness: 40,
            hoverEffect: false,
          },
        },
        series: {
          showLabels: false,
          values: [
            {
              name: 'Scheduled',
              value: 16,
              color: '#358FED',
            },
            {
              name: 'Expired',
              value: 12,
              color: '#0B54A0',
            },
          ],
        },
      },
    };
    this.intransitLoadsChart = {
      config: {
        margins: [2, 118, 2, 2],
        background: '#fff',
        displayLegend: true,
        legendStyle: {
          position: 4, // TsChartLegendPosition.MID_LEFT
          orientation: 0, // TsChartLegendOrientation.VERTICAL,
          fontSize: 10,
          width: 118,
        },
      },
      data: {
        type: 'donut',
        style: {
          donut: {
            borderColor: '#ffffff',
            borderWeight: 1,
            thickness: 40,
            hoverEffect: false,
          },
        },
        series: {
          showLabels: false,
          values: [
            {
              name: 'Delivered',
              value: 9,
              color: '#528000',
            },
            {
              name: 'Out for Delivery',
              value: 14,
              color: '#E25324',
            },
            {
              name: 'Delayed',
              value: 3,
              color: '#E28E2B',
            },
          ],
        },
      },
    };
    this.loadBarChart = {
      config: {
        margins: [40, 20, 30, 10],
        background: 'transparent',
        xAxis: {
          line: {
            weight: 0,
          },
          label: {
            color: '#060707',
            size: 10,
          },
        },
        yAxisHide: true,
      },
      data: {
        type: 'bar-vertical',
        style: {
          label: {
            color: '#6A6A6A',
            size: 10,
          },
          bar: {
            color: '#3F90CF',
            borderWeight: 0,
            cornerRadius: 8,
          },
        },
        xAxis: {
          name: '',
          namePadding: 0,
        },
        yAxis: {
          minValue: 0,
        },
        series: [
          {
            showLabels: true,
            showNames: true,
            labelOffset: 0,
            values: [
              {
                name: '05/04/2020',
                value: 20,
              },
              {
                name: '05/05/2020',
                value: 40,
              },
              {
                name: '05/06/2020',
                value: 60,
              },
              {
                name: '05/07/2020',
                value: 40,
              },
              {
                name: '05/08/2020',
                value: 20,
              },
              {
                name: '05/09/2020',
                value: 40,
              },
              {
                name: 'Today',
                value: 20,
              },
            ],
          },
        ],
      },
    };
    this.marginBarChart = {
      config: {
        margins: [40, 20, 30, 10],
        background: 'transparent',
        xAxis: {
          line: {
            weight: 0,
          },
          label: {
            color: '#3A3A3A',
            size: 10,
          },
        },
        yAxisHide: true,
      },
      data: {
        type: 'bar-vertical',
        style: {
          label: {
            color: '#6A6A6A',
            size: 10,
          },
          bar: {
            color: '#0DB3C3',
            borderWeight: 0,
            cornerRadius: 8,
          },
        },
        xAxis: {
          name: '',
          namePadding: 0,
        },
        yAxis: {
          minValue: 0,
        },
        series: [
          {
            showLabels: true,
            showNames: true,
            labelOffset: 0,
            labelPrefix: '$',
            values: [
              {
                name: '05/04/2020',
                value: 1000,
              },
              {
                name: '05/05/2020',
                value: 2000,
              },
              {
                name: '05/06/2020',
                value: 4000,
              },
              {
                name: '05/07/2020',
                value: 2000,
              },
              {
                name: '05/08/2020',
                value: 1000,
              },
              {
                name: '05/09/2020',
                value: 2000,
              },
              {
                name: 'Today',
                value: 2800,
              },
            ],
          },
        ],
      },
    };
  }

  onShipperChanged(shippersData) {
    this.shippersData = shippersData;
    this.shipperContact = shippersData.shipperContact;
  }

  // This function will be called on every key press for input text box
  onSearchChange() {
    if (
      this.searchForm.value.searchTerm &&
      this.searchForm.value.searchTerm.trim() !== ''
    ) {
      if (this.selectedTab === 0) {
        this._getShipperSearch(
          this.searchForm.value.searchTerm,
          '',
          this._currentPage,
          this.itemsPerPage
        );
      } else {
        this._getShipperSearch(
          this.searchForm.value.searchTerm,
          this.selectedTab,
          this._currentPage,
          this.itemsPerPage
        );
      }
    }
  }
  switchTab(event: MatTabChangeEvent) {
    this._currentPage = 1;
    if (event.index === 0) {
      this.selectedTab = 0;
      this._getShipperSearch('', '', this._currentPage, this.itemsPerPage);
    } else {
      this.selectedTab = event.index;
      this._getShipperSearch(
        '',
        event.index,
        this._currentPage,
        this.itemsPerPage
      );
    }
  }
  createProfileLogo(firstName, lastName) {
    if (firstName && lastName) {
      return (this.profileLogo = firstName.charAt(0) + '' + lastName.charAt(0));
    }
  }
  onPageSizeChanged(event: any): void {
    this.itemsPerPage = event.pageSize;
    this._currentPage = event.pageIndex + 1;
    if (this.selectedTab === 0) {
      this._getShipperSearch(
        this.searchForm.value.searchTerm,
        '',
        this._currentPage,
        this.itemsPerPage
      );
    } else {
      this._getShipperSearch(
        this.searchForm.value.searchTerm,
        this.selectedTab,
        this._currentPage,
        this.itemsPerPage
      );
    }
  }
  private _getShipperCounts() {
    this._customerService.getShipperCounts().subscribe(
      (res) => {
        if (res) {
          this.totalCustomer = res.totalCustomer;
          this.daysToPayGreater30 = res.daysToPayGreater30;
          this.daysToPayLess30 = res.daysToPayLess30;
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  private _getShipperSearch(value, dtp, index, size) {
    this._customerService.getShipperSearch(value, dtp, index, size).subscribe(
      (res) => {
        if (res) {
          console.log(res.data);
          this.shipperList = res.data;
          this.totalRecords = res.pagination.totalItemCount;
          this.shippersData = this.shipperList[0];
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }
}
