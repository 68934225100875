import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  opened: boolean = true;
  constructor(private router: Router) {}

  ngOnInit(): void {}
  events: string[] = [];

  toggle() {
    this.opened = !this.opened;
  }
  redirect(pagename: string) {
    this.router.navigate(['/' + pagename]);
  }
  onFocusEvent(event: any) {
    console.log(event.target.value);
  }
}
