<div mat-dialog-title>
  {{ title }}
  <div>
    <i
      class="fa fa-times float-right"
      aria-hidden="true"
      (click)="onConfirm()"
    ></i>
  </div>
</div>

<div mat-dialog-content>
  <p class="header">{{ message }}</p>

  <p class="sub-header">{{ submessage }}</p>
</div>

<div class="row justify-content-center my-2 mt-3">
  <button mat-button (click)="onClose()" class="cancelbtn mr-4">
    Cancel
  </button>
  <button mat-button class="submitbtn ml-3" (click)="onConfirm()">
    Delete
  </button>
</div>
