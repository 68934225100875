import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _authurl: string = environment.AUTHSERVICE_URL;
  private _isRenewing: boolean;
  constructor(private _api: ApiService, private _auth: AuthService) {}

  login(username: string, password: string, scope: string) {
    const body = {
      username: username,
      password: password,
      scope: scope,
    };
    return this._api
      .post('/token', body, this._authurl)
      .pipe(tap((res) => this.onUserLogin(res)));
  }

  loginById(Id): Observable<boolean> {
    return this._api.get(`/token/${Id}`, this._authurl).pipe(
      tap((res) => {
        this.onUserLogin(res);
      })
    );
  }

  renewToken() {
    const token = this._auth.getToken();
    if (!this._auth.isTokenExpired(token) || this._isRenewing) {
      return;
    }
    this._isRenewing = true;
    return this._api
      .post(
        '/renew',
        {
          token,
        },
        this._authurl
      )
      .pipe(
        tap((res) => {
          this._isRenewing = false;
          this._auth.setToken((res as any).accessToken);
        }),
        catchError((error) => {
          this._isRenewing = false;
          throw Error(error.error.message);
        })
      );
  }
  getUser(): Observable<any> {
    return this._api.get('/accountmanagement/v2/accountuser');
  }

  private onUserLogin(res) {
    this._auth.setToken((res as any).accessToken);
    const claims = this._auth.getClaims();
    localStorage.setItem('email', JSON.stringify(claims.userName));
  }
}
