import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { CustomersService } from '../../core/customer.service';
export interface DialogData {
  id: any;
  action: string;
}
export interface u_contact {
  id: any;
  updateContact: any;
  action: string;
}
@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.scss'],
})
export class AddContactDialogComponent implements OnInit {
  addContactForm: FormGroup;
  editContactForm: FormGroup;
  id: any;
  addedShipperContact: any;
  action: any;
  updateData: any;
  shipperContactId: any;
  constructor(
    public dialogRef: MatDialogRef<AddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _customerService: CustomersService
  ) {
    //this.addedShipperContact = { ...data };
  }

  ngOnInit() {
    this.action = this.data.action;

    if (this.data.action == 'add') {
      this.addContactForm = this.formBuilder.group({
        id: [null],
        contactTypeName: [null],
        firstName: [null],
        lastName: [null],
        phone: [null],
        ext: [null],
        mobile: [null],
        email: [null],
        isPrimary: [false],
      });
    } else if (this.data.action == 'edit') {
      this.editContactForm = this.formBuilder.group({
        //id: [null],
        contactTypeName: [null],
        firstName: [null],
        lastName: [null],
        phone: [null],
        ext: [null],
        mobile: [null],
        email: [null],
        isPrimary: [false],
      });
      this.updateData = this.data.updateContact;
      this.id = this.data.id;
      this.shipperContactId = this.updateData.id;
      // Set Values
      this.editContactForm.controls['contactTypeName'].setValue(
        this.updateData.contactTypeName
      );
      this.editContactForm.controls['firstName'].setValue(
        this.updateData.firstName
      );
      this.editContactForm.controls['lastName'].setValue(
        this.updateData.lastName
      );
      this.editContactForm.controls['phone'].setValue(this.updateData.phone);
      this.editContactForm.controls['ext'].setValue(this.updateData.ext);
      this.editContactForm.controls['mobile'].setValue(this.updateData.mobile);
      this.editContactForm.controls['email'].setValue(this.updateData.email);
      this.editContactForm.controls['isPrimary'].setValue(
        this.updateData.isPrimary
      );
    }
  }

  onSubmit() {
    this.id = this.data.id;
    this._customerService
      .createShipperContact(this.id, this.addContactForm.value)
      .subscribe(
        (res) => {
          if (res) {
            this.addedShipperContact = res;
            this.dialogRef.close(this.addedShipperContact);
          }
        },
        (e) => {
          console.log(e);
        }
      );
  }

  onUpdate() {
    this._customerService
      .updateShipperContact(
        this.id,
        this.shipperContactId,
        this.editContactForm.value
      )
      .subscribe(
        (res) => {
          if (res) {
            this.dialogRef.close(res);
          }
        },
        (e) => {
          console.log(e);
        }
      );
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor() {}
}
