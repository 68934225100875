<div class="bg-color">
  <mat-tab-group class="setting">
    <mat-tab label="Terms & Conditions">
      <div class="terms-and-conditions mt-3 ml-3">
        <ts-terms-and-conditions #tncRef> </ts-terms-and-conditions>
      </div>
    </mat-tab>
    <mat-tab label="Rate Confirmation">
      <div class="mt-3 ml-3">
        <ts-bin-pdf-setup #binPdfRef></ts-bin-pdf-setup>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
