// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://api-qa.truckstop.com',
  AUTHSERVICE_URL: 'https://v5-auth-qa.truckstop.com/auth',
  CHATSUPPORT_URL: 'https://d.la4-c2cs-dfw.salesforceliveagent.com/chat',
  LIVEAGENT_DEPLOYMENT_ID: '00D1b0000000liD',
  V4_APP_URL: 'https://test.truckstop.com',
  V5_APP_URL: 'https://alpha-qa.truckstop.com',
  SALESFORCE_URL: 'https://qastaging-truckstop.cs25.force.com',
  NOTIFICATIONS_URL: 'https://v5websocketsqa.truckstop.com',
  PANGEA_LOCATION_SERVICE: 'https://loadtracking-qa-rest.truckstop.com',
  PANGEA_AUTORIZATION_KEY: '9c9b05aff7214e3a8a6ae2b7e708421e',
  PRIME_URL: 'https://api-qa.truckstopprivate.net',
  FEATURE_FLAGS: {
    DragAndDropRefactor: false,
    CarrierBINManager: true,
    groupsFeature: true,
    hideCarrierGroupUpload: false,
    MigrationV4toV5Enabled: true,
    IsV5Host: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
