<ts-dialog-header class="ts-dialog-header-demo">
  <span>Delete Stop?</span>
  <ts-button
    icon="close"
    class="close-icon"
    icon-only="true"
    id="confirmation-close-icon"
  ></ts-button>
</ts-dialog-header>
<ts-dialog-body>
  <div>Are you sure that you would like to delete this stop?</div>
</ts-dialog-body>
<ts-dialog-footer class="ts-dialog-footer-demo">
  <ts-button
    outlined
    type="secondary"
    class="mdc-button mdc-dialog__button secondary-with-color hover-focus-indicator-color"
    (click)="closeDialog(false)"
  >
    cancel</ts-button
  >
  <ts-button
    type="primary"
    class="mdc-button mdc-dialog__button primary-with-color hover-focus-indicator-color"
    (click)="closeDialog(true)"
  >
    delete</ts-button
  >
</ts-dialog-footer>
