<mat-toolbar class="profile-header">
  <ts-icon name="arrow-left" class="mr-3" (click)="onBackButton()"></ts-icon>
  <span>My Profile</span>
</mat-toolbar>
<div>
  <div class="row">
    <div class="col-4">
      <span class="profile-upload">Admin Account Details</span>
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <label class="hoverable" for="fileInput">
              <img
                [src]="url ? url : '../../../assets/icons/svg/Jim-Vrtis.jpg'"
              />
              <div class="hover-text">
                <ts-icon name="camera" class="camera-icon"></ts-icon>
              </div>

              <div class="background"></div>
            </label>
            <br />
            <input id="fileInput" type="file" (change)="onSelectFile($event)" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-8 font-weight-bold">
      Profile Information
      <!-- main app container -->
      <div class="card m-3">
        <div class="card-body">
          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
              <div class="form-group col-5">
                <label>First Name</label>
                <input
                  type="text"
                  formControlName="firstName"
                  class="form-control height-form"
                  [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                />
                <div
                  *ngIf="submitted && f.firstName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.firstName.errors.required">
                    First Name is required
                  </div>
                </div>
              </div>
              <div class="form-group col-5">
                <label>Last Name</label>
                <input
                  type="text"
                  formControlName="lastName"
                  class="form-control height-form"
                  [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                />
                <div
                  *ngIf="submitted && f.lastName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.lastName.errors.required">
                    Last Name is required
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col">
                <label>Phone</label>
                <input
                  type="text"
                  formControlName="phone"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                />
                <div
                  *ngIf="submitted && f.phone.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.phone.errors.required">Phone is required</div>
                </div>
              </div>
              <div class="form-group col">
                <label>Ext</label>
                <input
                  type="text"
                  formControlName="ext"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.ext.errors }"
                />
                <div *ngIf="submitted && f.ext.errors" class="invalid-feedback">
                  <div *ngIf="f.ext.errors.required">Ext is required</div>
                </div>
              </div>
              <div class="form-group col">
                <label>Mobile Phone(Optional)</label>
                <input
                  type="text"
                  formControlName="mobile"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <label>Email Address</label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">
                  Email must be a valid email address
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Address Line-1</label>
              <input
                type="text"
                formControlName="address1"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.address1.errors }"
              />
              <div
                *ngIf="submitted && f.address1.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.address1.errors.required">
                  Address Line 1 is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Address Line-2</label>
              <input
                type="text"
                formControlName="address2"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.address2.errors }"
              />
              <div
                *ngIf="submitted && f.address2.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.address2.errors.required">
                  Address Line 2 is required
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col">
                <label>City</label>
                <input
                  type="text"
                  formControlName="city"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                />
                <div
                  *ngIf="submitted && f.city.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>
              <div class="form-group col">
                <label>State</label>
                <input
                  type="text"
                  formControlName="state"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                />
                <div
                  *ngIf="submitted && f.state.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.state.errors.required">State is required</div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col">
                <label>Country</label>
                <input
                  type="text"
                  formControlName="country"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
                />
                <div
                  *ngIf="submitted && f.country.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.country.errors.required">
                    Country is required
                  </div>
                </div>
              </div>
              <div class="form-group col">
                <label>Zip Code</label>
                <input
                  type="number"
                  formControlName="zip"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.zip.errors }"
                />
                <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                  <div *ngIf="f.zip.errors.required">Zip Code is required</div>
                </div>
              </div>
            </div>

            <div class="text-center">
              <button
                class="btn btn-cancel mr-3"
                type="reset"
                (click)="onReset()"
              >
                Cancel
              </button>
              <button class="btn btn-submit mr-1">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
