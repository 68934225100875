import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AddContactDialogComponent } from './components/add-contact-dialog/add-contact-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './utility/material.module';
import { LibraryModule } from './utility/library.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './core/auth.service';
import { LoginService } from './core/login.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/token.interceptor';
import { CoreModule } from './core/core.module';
import { MyprofileComponent } from './pages/myprofile/myprofile.component';
import { CustomerComponent } from './pages/customers/customer.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { AgGridModule } from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SettingsComponent } from './pages/settings/settings.component';
import { NewloadComponent } from './pages/loads/newload/newload.component';
import { ListViewComponent } from './pages/loads/list-view/list-view.component';
import { LoadComponent } from './pages/loads/load/load.component';
import { DialogComponent } from './components/dialog/dialog/dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FavoritesComponent } from './pages/loads/favorites/favorites.component';
import { UploadComponent } from './pages/loads/upload/upload.component';
import { TabViewComponent } from './pages/loads/tab-view/tab-view.component';
import { ActionViewComponent } from './pages/loads/action-view/action-view.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MenuComponent,
    ToolbarComponent,
    MyprofileComponent,
    CustomerComponent,
    AddCustomerComponent,
    CustomerDetailsComponent,
    SettingsComponent,
    AddContactDialogComponent,
    ConfirmDialogComponent,
    ListViewComponent,
    NewloadComponent,
    LoadComponent,
    DialogComponent,
    FavoritesComponent,
    UploadComponent,
    TabViewComponent,
    ActionViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    MaterialModule,
    LibraryModule,
    ReactiveFormsModule,
    CoreModule,
    MatTabsModule,
    MatTableModule,
    MatStepperModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AuthService,
    LoginService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
