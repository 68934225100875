import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
})
export class MyprofileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  url;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  onBackButton() {
    this.router.navigate(['/app']);
  }
  ngOnInit() {
    this.profileForm = this.formBuilder.group(
      {
        firstName: ['Jim', Validators.required],
        lastName: ['Vrtis', Validators.required],
        phone: ['312-509-6995', Validators.required],
        ext: ['872', Validators.required],
        mobile: ['2089532532'],
        email: ['jimvrtis@gmail.com', [Validators.required, Validators.email]],
        address1: ['Fox River Grove', Validators.required],
        address2: [
          'Address 305 Illinois St Fox River Grove',
          Validators.required,
        ],
        city: ['Chicago', Validators.required],
        state: ['IL', Validators.required],
        country: ['USA', Validators.required],
        zip: ['60412', Validators.required],
      },
      {
        validator: Validators.required,
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }

    // display form values on success
    alert(
      'SUCCESS!! :-)\n\n' + JSON.stringify(this.profileForm.value, null, 4)
    );
  }

  onReset() {
    this.submitted = false;
    this.profileForm.reset();
  }

  // fileProgress(fileInput: any) {
  //     this.fileData = (fileInput.target.files[0] as File);
  //     this.preview();
  // }
  // preview() {
  //     // Show preview
  //     const mimeType = this.fileData.type;
  //     if (mimeType.match(/image\/*/) == null) {
  //         return;
  //     }

  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.fileData);
  //     reader.onload = (_event) => {
  //         this.previewUrl = reader.result;
  //     }
  // }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
  }
  public delete() {
    this.url = null;
  }
  onSubmitimage() {
    const formData = new FormData();
    formData.append('files', this.fileData);
    this.fileUploadProgress = '0%';
    this.http
      .post(
        'https://us-central1-tutorial-e6ea7.cloudfunctions.net/fileUpload',
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .subscribe((events) => {
        if (events.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress =
            Math.round((events.loaded / events.total) * 100) + '%';
          console.log(this.fileUploadProgress);
        } else if (events.type === HttpEventType.Response) {
          this.fileUploadProgress = '';
          console.log(events.body);
          alert('SUCCESS !!');
        }
      });
  }
}
