import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../core/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  public LookupAddress(address: string) {
    return this.http.get<string[]>(
      'https://api-qa.truckstop.com/geolocation/v2/autocomplete/' + address,
      { headers: this.headers() }
    );
  }

  public LookupCommodity() {
    return this.http
      .get(
        'https://api-qa.truckstop.com/freightmatching/v1/domaindata/commodities',
        { headers: this.headers() }
      )
      .pipe(map((x: any) => (x.message === 'OK' ? x.data : [])));
  }

  public LookupEquipmentTypes(): any {
    return this.http
      .get(
        'https://api-qa.truckstop.com/freightmatching/v1/domaindata/equipmenttypes/load/byrank/internal',
        { headers: this.headers() }
      )
      .pipe(map((x: any) => (x.message === 'OK' ? x.data : [])));
  }
  public LookupMode() {
    return this.http
      .get(
        'https://api-qa.truckstop.com/freightmatching/v1/domaindata/transportationmodes/internal',
        { headers: this.headers() }
      )
      .pipe(map((x: any) => (x.message === 'OK' ? x.data : [])));
  }
  public LookupEquipmentOptions() {
    return this.http
      .get(
        'https://api-qa.truckstop.com/freightmatching/v1/domaindata/equipmentoptions/internal',
        { headers: this.headers() }
      )
      .pipe(map((x: any) => (x.message === 'OK' ? x.data : [])));
  }
  public LookupTerms() {
    const dto = JSON.stringify({
      pagination: { pageNumber: 1, pageSize: 100000 },
    });
    return this.http
      .post(
        'https://api-qa.truckstop.com/termsandconditions/v1/ratecon/search',
        dto,
        { headers: this.headers() }
      )
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
  public LookupPostAs(): any {
    const dto = JSON.stringify({
      pagination: { pageNumber: 1, pageSize: 100000 },
    });
    return this.http
      .post(
        'https://api-qa.truckstop.com/accountmanagement/v1/accountuser/active',
        dto,
        { headers: this.headers() }
      )
      .pipe(
        map((res: any) => {
          return res.results[0].data;
        })
      );
  }
  public LookupLocation(location: string): any {
    return this.http.get(
      'https://api-qa.truckstop.com/geolocation/v2/location/' + location,
      { headers: this.headers() }
    );
  }

  public LookupLoad(dto: any) {
    const body = JSON.stringify(dto);
    return this.http
      .post(
        'https://loadtracking-qa-rest.truckstopprivate.net/External/FreightMatching/Rates',
        body,
        { headers: this.headers() }
      )
      .pipe(
        map((res: any) => {
          const data = res.Body.Data || {};
          const rateLookup: RateLookup = {
            posted7DayRate: Number.parseFloat(data.posted7DayRate),
            posted15DayRate: Number.parseFloat(data.posted15DayRate),
            posted30DayRate: Number.parseFloat(data.posted30DayRate),
            posted90DayRate: Number.parseFloat(data.posted90DayRate),
          };
          return rateLookup;
        })
      );
  }

  public headers() {
    const headers = {
      Authorization: `Bearer ${this.authService.getTokenInfo().accessToken}`,
      'Content-Type': 'application/json',
      authorize: '9c9b05aff7214e3a8a6ae2b7e708421e',
    };
    return headers;
  }
}

export interface EquipmentLookup {
  code: string;
  description: string;
  equipmentCategoryId: number;
  id: number;
  rank: number;
  v5Id: number;
}
export interface EquipmentOptionsLookup {
  code: string;
  description: string;
  id: string;
  v5ID: number;
}
export interface ModeLookup {
  modeCode: string;
  modeDescription: string;
  modeId: number;
  pof: string;
  v5ModeId: number;
}
export interface TermsLookup {
  content: string;
  createdBy: string;
  createdByAccountId: string;
  createdDateTime: string;
  id: string;
  isDeleted: boolean;
  name: string;
  updatedBy: string;
  updatedDateTime: string;
}
export interface PostAsLookup {
  accountId: string;
  alias: string;
  emailAddress: string;
  firstName: string;
  id: string;
  isActive: boolean;
  isDefault: boolean;
  lastName: string;
  mobile: string;
  phone: string;
  userId: string;
  username: string;
}
export interface CommodityLookup {
  description: string;
  id: string;
  name: string;
  v5Id: number;
}
export interface RateLookup {
  posted7DayRate: number;
  posted15DayRate: number;
  posted30DayRate: number;
  posted90DayRate: number;
}
