<div class="load p-4">
  <body marginheight="0" marginwidth="0">
    <div class="d-flex flex-column align-items-center p-5">
      <div class="p-8" (click)="fileUpload.click()">
        <ts-icon class="upload" name="upload-computer"></ts-icon>
      </div>
      <div class="txt p-8">Drag & Drop Files to Upload</div>
      <div class="p-8">
        <ts-button
          outlined
          (click)="fileUpload.click()"
          class="primary-with-color hover-focus-indicator"
        >
          UPLOAD A SELECTED FILE <input type="file" hidden />
        </ts-button>
      </div>
      <div class="row d-flex position-relative p-4">
        <ts-icon name="information-circle" class="info-ico-color"></ts-icon>
        <a
          id="displayHelp"
          href="https://test.truckstop.com/includes/DragNDropLoadsHelp.pdf"
          target="_blank"
        >
          <span class="downloadLink">Display help information</span>
        </a>
      </div>
      <div class="row d-flex position-relative p-4">
        <ts-icon name="download"></ts-icon>
        <a
          id="dataLink"
          download="LoadsTemplate.csv"
          href="data:Application/octet-stream .csv,Starting%20City%2C%20Starting%20State%2C%20Starting%20Country%2C%20Starting%20Zip%2C%20Destination%20City%2C%20Destination%20State%2C%20Destination%20Country%2C%20Destination%20Zip%2C%20Type%20of%20Equipment%2C%20Rate%2C%20Pickup%20Date%2C%20PickUp%20Time%2C%20Delivery%20Date%2C%20Delivery%20Time%2C%20TL%20or%20LTL%2C%20Equipment%20Options%2C%20Weight%2C%20Length%2C%20Width%2C%20Stops%2C%20Distance%2C%20Quantity%2C%20Special%20Information%2C%20IsDaily%0A"
        >
          <span id="downloadLink" class="downloadLink">Download template</span>
        </a>
      </div>
    </div>
  </body>
  <input
    type="file"
    #fileUpload
    accept=".csv"
    style="
      visibility: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 0px;
      width: 0px;
    "
  />
  <mat-divider class="md-6"></mat-divider>
  <div
    class="d-flex align-items-center justify-content-around row col-12 col md-6 p-2"
  >
    <ts-button
      outlined
      class="secondary-with-color hover-focus-indicator-color"
    >
      EXIT LOAD POST</ts-button
    >
  </div>
</div>
