import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtHelper {
  _jwtHelper = new JwtHelperService();
  getJwtHelper() {
    return this._jwtHelper;
  }
}
