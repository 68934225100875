<div class="bg-color">
  <mat-tab-group
    class="pt-3 customer-header"
    (selectedTabChange)="switchTab($event)"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab>
      <ng-template mat-tab-label class="tab-lable">
        <!-- <img
          src="../../../assets/icons/svg/total-customer.png"
          class="customers-image"
          height="20"
          width="20"
        /> -->
        <ts-icon name="user-people-2" class="total-customer-icon"></ts-icon>
        <span class="pl-3 customer-count">{{ totalCustomer }}</span>
        <div class="customer-lable mt-3">TOTAL CUSTOMERS</div>
      </ng-template>
      <div class="row p-2">
        <div class="col-4 p-0">
          <div class="d-flex ml-3">
            <form [formGroup]="searchForm" class="w-100">
              <input
                type="text"
                class="form-control search"
                placeholder="Search"
                formControlName="searchTerm"
                (input)="onSearchChange()"
              />
            </form>

            <button mat-mini-fab class="ml-2" routerLink="/app/add-customer">
              <ts-icon name="plus"></ts-icon>
            </button>
          </div>
          <div>
            <mat-selection-list [multiple]="false">
              <mat-list-option
                *ngFor="let shipper of shipperList; let i = index"
                [selected]="shipper.selected"
                (click)="onShipperChanged(shipper)"
                [value]="shipper"
                [selected]="i === 0"
              >
                <!-- <img mat-list-icon src="{{shipper.profileIcon}}" class="customers-image" height="50" /> -->
                <div class="shipper-profile" mat-list-icon>
                  <div class="profile-icon">
                    {{
                      createProfileLogo(
                        (shipper?.shipperContacts)[0]?.firstName,
                        (shipper?.shipperContacts)[0]?.lastName
                      )
                    }}
                  </div>
                </div>
                <div mat-line class="name-list">{{ shipper.name }}</div>
                <div mat-line class="sub-list">
                  {{ shipper.businessType }}
                  <ts-icon
                    name="ellipsis-v"
                    [matMenuTriggerFor]="menu"
                    class="menu-icon"
                  ></ts-icon>
                </div>
                <mat-menu #menu="matMenu" xPosition="before">
                  <div
                    mat-menu-item
                    routerLink="/app/customer-details"
                    [queryParams]="{
                      shipperData: shipper | json
                    }"
                  >
                    <ts-icon name="pencil"></ts-icon>
                    <span class="ml-3 align-middle">Edit</span>
                  </div>
                </mat-menu>
              </mat-list-option>
            </mat-selection-list>
            <div *ngIf="shipperList && !shipperList.length" class="text-center">
              No Shipper List Found
            </div>
            <mat-paginator
              *ngIf="shipperList && shipperList.length"
              (page)="onPageSizeChanged($event)"
              [length]="totalRecords"
              [pageSize]="itemsPerPage"
              hidePageSize
            >
            </mat-paginator>
          </div>
        </div>

        <div class="col-8 gray-bg">
          <div class="row mt-3">
            <div class="col-4 customer-detail ml-2">
              <mat-tab-group class="tab-details">
                <mat-tab label="ACTIVE LOADS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >16 <ts-icon name="user" class="user"></ts-icon></span
                    ><span class="loads ml-3">ACTIVE LOADS</span>
                    <p class="active-content">
                      Active loads are Opportunities, Open and Loads In-Transit.
                    </p>
                  </div>
                </mat-tab>
                <mat-tab label="LOAD ALERTS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >6
                      <ts-icon
                        name="notifications"
                        class="user"
                      ></ts-icon></span
                    ><span class="loads ml-3">LOAD ALERTS</span>
                    <div class="row load-alert">
                      <div class="col">
                        Delivered : 4
                      </div>
                      <div class="col">
                        Dropped: 0
                      </div>
                    </div>
                    <div class="row load-alert">
                      <div class="col">
                        Delayed : 1
                      </div>
                      <div class="col">
                        Expired : 1
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col mx-2 bg-white">
              <div *ngIf="shippersData">
                <p class="mt-2">
                  <span class="contact-head">Customer Contact </span
                  ><ts-icon
                    name="pencil"
                    class="pencil ml-3 gray"
                    [routerLink]="['/app/customer-details']"
                    [queryParams]="{
                      tab: 2,
                      id: shippersData.id,
                      contacts: shippersData.shipperContacts | json
                    }"
                  ></ts-icon>
                </p>
                <hr />
              </div>
              <div class="row" *ngIf="shippersData">
                <div class="col-6 border-right">
                  <img
                    mat-list-icon
                    src="../../../assets/icons/svg/vector.png"
                    class="mr-3"
                    height="25"
                  />
                  <span class="shipper-name">{{ shippersData.name }}</span>
                  <p class="shipper-address">
                    {{ shippersData.businessType }}
                  </p>
                  <p>
                    <ts-icon name="building" class="mr-3 gray"></ts-icon>
                    <span class="shipper-address-line">
                      {{ shippersData.mainAddress1 }},
                      {{ shippersData.mainCity }}, {{ shippersData.mainState }},
                      {{ shippersData.mainStateCode }},
                      {{ shippersData.mainCountryCode }},
                      {{ shippersData.mainZipCode }}
                    </span>
                  </p>
                </div>
                <div
                  class="col-6"
                  *ngFor="
                    let contact of shippersData.shipperContacts;
                    let j = index
                  "
                >
                  <div *ngIf="j == 0">
                    <p class="pt-1">
                      <ts-icon name="user" class="mr-3 gray"></ts-icon
                      >{{ contact.firstName }} {{ contact.lastName }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="phone" class="mr-3 gray"></ts-icon
                      >{{ contact.phone }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="envelope" class="mr-3 gray"></ts-icon>
                      <a href="mailto:smithdoor@yahoo.com">{{
                        contact.email
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              Customer Load Status
              <hr />
            </div>

            <div class="row px-3">
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="calendar-check"
                    class="align-middle mr-3"
                  ></ts-icon>
                  OPEN LOADS
                  <span class="ml-3">37</span>
                </p>
                <p class="open-load-chart">
                  <ts-chart
                    [config]="openLoadsChart.config"
                    [data]="openLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="closed-load"
                    class="align-middle mr-3"
                  ></ts-icon>
                  COVERED LOADS
                  <span class="ml-3">28</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="coveredLoadsChart.config"
                    [data]="coveredLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col">
                <p class="load-status-header">
                  <ts-icon
                    name="map-tracking-active"
                    class="align-middle mr-3"
                  ></ts-icon>
                  IN-TRANSIT LOADS
                  <span class="ml-3">26</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="intransitLoadsChart.config"
                    [data]="intransitLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              <mat-tab-group class="load-status-tab">
                <mat-tab label="Loads">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="loadBarChart.config"
                      [data]="loadBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
                <mat-tab label="Margin">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="marginBarChart.config"
                      [data]="marginBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
              </mat-tab-group>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="tab-lable">
        <!-- <img
          src="../../../assets/icons/svg/customer-online.png"
          class="customers-image"
          height="25"
          width="25"
        /> -->
        <ts-icon name="user-people-2" class="ldtp-customer-icon"></ts-icon>
        <span class="pl-3 customer-count">{{ daysToPayLess30 }}</span>
        <div class="customer-lable mt-3">
          < 30 DAYS-TO-PAY
        </div>
      </ng-template>
      <div class="row p-2">
        <div class="col-4">
          <div class="d-flex ml-3">
            <form [formGroup]="searchForm" class="w-100">
              <input
                type="text"
                class="form-control search"
                placeholder="Search"
                formControlName="searchTerm"
                (input)="onSearchChange()"
              />
            </form>
            <button mat-mini-fab class="ml-2" routerLink="/app/add-customer">
              <ts-icon name="plus"></ts-icon>
            </button>
          </div>
          <div>
            <mat-selection-list [multiple]="false">
              <mat-list-option
                *ngFor="let shipper of shipperList; let i = index"
                [selected]="shipper.selected"
                (focus)="onShipperChanged(shipper)"
                [value]="shipper"
                [selected]="i === 0"
              >
                <!-- <img mat-list-icon src="" class="customers-image" height="50" /> -->
                <div class="shipper-profile" mat-list-icon>
                  <div class="profile-icon">
                    {{
                      createProfileLogo(
                        (shipper?.shipperContacts)[0]?.firstName,
                        (shipper?.shipperContacts)[0]?.lastName
                      )
                    }}
                  </div>
                </div>
                <div mat-line class="name-list">{{ shipper.name }}</div>
                <div mat-line class="sub-list">
                  {{ shipper.businessType }}
                  <ts-icon
                    name="ellipsis-v"
                    [matMenuTriggerFor]="menu"
                    class="menu-icon"
                  ></ts-icon>
                </div>
                <mat-menu #menu="matMenu" xPosition="before">
                  <div
                    mat-menu-item
                    routerLink="/app/customer-details"
                    [queryParams]="{
                      shipperData: shipper | json
                    }"
                  >
                    <ts-icon name="pencil"></ts-icon>
                    <span class="ml-3 align-middle">Edit</span>
                  </div>
                </mat-menu>
              </mat-list-option>
            </mat-selection-list>
            <div *ngIf="shipperList && !shipperList.length" class="text-center">
              No Shipper List Found
            </div>
            <mat-paginator
              *ngIf="shipperList && shipperList.length"
              (page)="onPageSizeChanged($event)"
              [length]="totalRecords"
              [pageSize]="itemsPerPage"
              hidePageSize
            >
            </mat-paginator>
          </div>
        </div>

        <div class="col-8 gray-bg">
          <div class="row mt-3">
            <div class="col-4 customer-detail ml-2">
              <mat-tab-group class="tab-details">
                <mat-tab label="ACTIVE LOADS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >16 <ts-icon name="user" class="user"></ts-icon></span
                    ><span class="loads ml-3">ACTIVE LOADS</span>
                    <p class="active-content">
                      Active loads are Opportunities, Open and Loads In-Transit.
                    </p>
                  </div>
                </mat-tab>
                <mat-tab label="LOAD ALERTS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >6
                      <ts-icon
                        name="notifications"
                        class="user"
                      ></ts-icon></span
                    ><span class="loads ml-3">LOAD ALERTS</span>
                    <div class="row load-alert">
                      <div class="col">
                        Delivered : 4
                      </div>
                      <div class="col">
                        Dropped: 0
                      </div>
                    </div>
                    <div class="row load-alert">
                      <div class="col">
                        Delayed : 1
                      </div>
                      <div class="col">
                        Expired : 1
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col mx-2 bg-white">
              <div *ngIf="shippersData">
                <p class="mt-2">
                  <span class="contact-head">Customer Contact </span
                  ><ts-icon
                    name="pencil"
                    class="pencil ml-3 gray"
                    [routerLink]="['/app/customer-details']"
                    [queryParams]="{
                      tab: 2,
                      id: shippersData.id,
                      contacts: shippersData.shipperContacts | json
                    }"
                  ></ts-icon>
                </p>
                <hr />
              </div>
              <div class="row" *ngIf="shippersData">
                <div class="col-6 border-right">
                  <img
                    mat-list-icon
                    src="../../../assets/icons/svg/vector.png"
                    class="mr-3"
                    height="25"
                  />
                  <span class="shipper-name">{{ shippersData.name }}</span>
                  <p class="shipper-address">
                    {{ shippersData.businessType }}
                  </p>
                  <p>
                    <ts-icon name="building" class="mr-3 gray"></ts-icon>
                    <span class="shipper-address-line">
                      {{ shippersData.mainAddress1 }},
                      {{ shippersData.mainCity }}, {{ shippersData.mainState }},
                      {{ shippersData.mainStateCode }},
                      {{ shippersData.mainCountryCode }},
                      {{ shippersData.mainZipCode }}
                    </span>
                  </p>
                </div>
                <div
                  class="col-6"
                  *ngFor="
                    let contact of shippersData.shipperContacts;
                    let j = index
                  "
                >
                  <div *ngIf="j == 0">
                    <p class="pt-1">
                      <ts-icon name="user" class="mr-3 gray"></ts-icon
                      >{{ contact.firstName }} {{ contact.lastName }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="phone" class="mr-3 gray"></ts-icon
                      >{{ contact.phone }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="envelope" class="mr-3 gray"></ts-icon>
                      <a href="mailto:smithdoor@yahoo.com">{{
                        contact.email
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              Customer Load Status
              <hr />
            </div>

            <div class="row px-3">
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="calendar-check"
                    class="align-middle mr-3"
                  ></ts-icon>
                  OPEN LOADS
                  <span class="ml-3">37</span>
                </p>
                <p class="open-load-chart">
                  <ts-chart
                    [config]="openLoadsChart.config"
                    [data]="openLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="closed-load"
                    class="align-middle mr-3"
                  ></ts-icon>
                  COVERED LOADS
                  <span class="ml-3">28</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="coveredLoadsChart.config"
                    [data]="coveredLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col">
                <p class="load-status-header">
                  <ts-icon
                    name="map-tracking-active"
                    class="align-middle mr-3"
                  ></ts-icon>
                  IN-TRANSIT LOADS
                  <span class="ml-3">26</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="intransitLoadsChart.config"
                    [data]="intransitLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              <mat-tab-group class="load-status-tab">
                <mat-tab label="Loads">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="loadBarChart.config"
                      [data]="loadBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
                <mat-tab label="Margin">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="marginBarChart.config"
                      [data]="marginBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
              </mat-tab-group>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="tab-lable">
        <!-- <img
          src="../../../assets/icons/svg/frame.png"
          class="customers-image"
          height="20"
          width="20"
        /> -->
        <ts-icon name="user-people-2" class="gdtp-customer-icon"></ts-icon>
        <span class="pl-3 customer-count">{{ daysToPayGreater30 }}</span>
        <div class="customer-lable mt-3">> 30 DAYS-TO-PAY</div>
      </ng-template>
      <div class="row p-2">
        <div class="col-4">
          <div class="d-flex ml-3">
            <form [formGroup]="searchForm" class="w-100">
              <input
                type="text"
                class="form-control search"
                placeholder="Search"
                formControlName="searchTerm"
                (input)="onSearchChange()"
              />
            </form>
            <button mat-mini-fab class="ml-2" routerLink="/app/add-customer">
              <ts-icon name="plus"></ts-icon>
            </button>
          </div>
          <div>
            <mat-selection-list [multiple]="false">
              <mat-list-option
                *ngFor="let shipper of shipperList; let i = index"
                [selected]="shipper.selected"
                (click)="onShipperChanged(shipper)"
                [value]="shipper"
                [selected]="i === 0"
              >
                <!-- <img mat-list-icon src="" class="customers-image" height="50" /> -->
                <div class="shipper-profile" mat-list-icon>
                  <div class="profile-icon">
                    {{
                      createProfileLogo(
                        (shipper?.shipperContacts)[0]?.firstName,
                        (shipper?.shipperContacts)[0]?.lastName
                      )
                    }}
                  </div>
                </div>
                <div mat-line class="name-list">{{ shipper.name }}</div>
                <div mat-line class="sub-list">
                  {{ shipper.businessType }}
                  <ts-icon
                    name="ellipsis-v"
                    [matMenuTriggerFor]="menu"
                    class="menu-icon"
                  ></ts-icon>
                </div>
                <mat-menu #menu="matMenu" xPosition="before">
                  <div
                    mat-menu-item
                    routerLink="/app/customer-details"
                    [queryParams]="{
                      shipperData: shipper | json
                    }"
                  >
                    <ts-icon name="pencil"></ts-icon>
                    <span class="ml-3 align-middle">Edit</span>
                  </div>
                </mat-menu>
              </mat-list-option>
            </mat-selection-list>
            <div *ngIf="shipperList && !shipperList.length" class="text-center">
              No Shipper List Found
            </div>
            <mat-paginator
              *ngIf="shipperList && shipperList.length"
              (page)="onPageSizeChanged($event)"
              [length]="totalRecords"
              [pageSize]="itemsPerPage"
              hidePageSize
            >
            </mat-paginator>
          </div>
        </div>

        <div class="col-8 gray-bg">
          <div class="row mt-3">
            <div class="col-4 customer-detail ml-2">
              <mat-tab-group class="tab-details">
                <mat-tab label="ACTIVE LOADS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >16 <ts-icon name="user" class="user"></ts-icon></span
                    ><span class="loads ml-3">ACTIVE LOADS</span>
                    <p class="active-content">
                      Active loads are Opportunities, Open and Loads In-Transit.
                    </p>
                  </div>
                </mat-tab>
                <mat-tab label="LOAD ALERTS">
                  <div class="mt-3 px-2">
                    <span class="active-load"
                      >6
                      <ts-icon
                        name="notifications"
                        class="user"
                      ></ts-icon></span
                    ><span class="loads ml-3">LOAD ALERTS</span>
                    <div class="row load-alert">
                      <div class="col">
                        Delivered : 4
                      </div>
                      <div class="col">
                        Dropped: 0
                      </div>
                    </div>
                    <div class="row load-alert">
                      <div class="col">
                        Delayed : 1
                      </div>
                      <div class="col">
                        Expired : 1
                      </div>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="col mx-2 bg-white">
              <div *ngIf="shippersData">
                <p class="mt-2">
                  <span class="contact-head">Customer Contact </span
                  ><ts-icon
                    name="pencil"
                    class="pencil ml-3 gray"
                    [routerLink]="['/app/customer-details']"
                    [queryParams]="{
                      tab: 2,
                      id: shippersData.id,
                      contacts: shippersData.shipperContacts | json
                    }"
                  ></ts-icon>
                </p>
                <hr />
              </div>
              <div class="row" *ngIf="shippersData">
                <div class="col-6 border-right">
                  <img
                    mat-list-icon
                    src="../../../assets/icons/svg/vector.png"
                    class="mr-3"
                    height="25"
                  />
                  <span class="shipper-name">{{ shippersData.name }}</span>
                  <p class="shipper-address">
                    {{ shippersData.businessType }}
                  </p>
                  <p>
                    <ts-icon name="building" class="mr-3 gray"></ts-icon>
                    <span class="shipper-address-line">
                      {{ shippersData.mainAddress1 }},
                      {{ shippersData.mainCity }}, {{ shippersData.mainState }},
                      {{ shippersData.mainStateCode }},
                      {{ shippersData.mainCountryCode }},
                      {{ shippersData.mainZipCode }}
                    </span>
                  </p>
                </div>
                <div
                  class="col-6"
                  *ngFor="
                    let contact of shippersData.shipperContacts;
                    let j = index
                  "
                >
                  <div *ngIf="j == 0">
                    <p class="pt-1">
                      <ts-icon name="user" class="mr-3 gray"></ts-icon
                      >{{ contact.firstName }} {{ contact.lastName }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="phone" class="mr-3 gray"></ts-icon
                      >{{ contact.phone }}
                    </p>
                    <p class="pt-1">
                      <ts-icon name="envelope" class="mr-3 gray"></ts-icon>
                      <a href="mailto:smithdoor@yahoo.com">{{
                        contact.email
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              Customer Load Status
              <hr />
            </div>

            <div class="row px-3">
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="calendar-check"
                    class="align-middle mr-3"
                  ></ts-icon>
                  OPEN LOADS
                  <span class="ml-3">37</span>
                </p>
                <p class="open-load-chart">
                  <ts-chart
                    [config]="openLoadsChart.config"
                    [data]="openLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col border-right">
                <p class="load-status-header">
                  <ts-icon
                    name="closed-load"
                    class="align-middle mr-3"
                  ></ts-icon>
                  COVERED LOADS
                  <span class="ml-3">28</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="coveredLoadsChart.config"
                    [data]="coveredLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
              <div class="col">
                <p class="load-status-header">
                  <ts-icon
                    name="map-tracking-active"
                    class="align-middle mr-3"
                  ></ts-icon>
                  IN-TRANSIT LOADS
                  <span class="ml-3">26</span>
                </p>
                <p class="covered-load-chart">
                  <ts-chart
                    [config]="intransitLoadsChart.config"
                    [data]="intransitLoadsChart.data"
                  >
                  </ts-chart>
                </p>
              </div>
            </div>
          </div>

          <div class="bg-white mt-3">
            <div class="contact-head col pt-3">
              <mat-tab-group class="load-status-tab">
                <mat-tab label="Loads">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="loadBarChart.config"
                      [data]="loadBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
                <mat-tab label="Margin">
                  <p class="bar-chart">
                    <ts-chart
                      [config]="marginBarChart.config"
                      [data]="marginBarChart.data"
                    >
                    </ts-chart>
                  </p>
                </mat-tab>
              </mat-tab-group>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
